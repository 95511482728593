.play-area {
    margin-left: 200px;
    overflow: hidden; }
    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
      .play-area {
        margin-left: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .play-area {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .play-area {
        margin-left: 0; } }
    @media (max-width: 767px) {
      .play-area {
        margin-left: 0; } }
  
    .play-thumb,
    .play-thumb2 {
        position: relative;
        height: 830px; 
        background-image: url("../../../assets/Market/play-bg.jpg");
        background-attachment: fixed;
        background-size: cover;
    }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .play-thumb, .play-thumb2 {
        height: 500px; } 
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .play-thumb, .play-thumb2 {
        height: 400px; } 
    }
    @media (max-width: 767px) {
      .play-thumb, .play-thumb2 {
        height: 400px; } 
    }
    .play-thumb::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
      z-index: 1; 
    }
    .play-thumb .play-btn,
    .play-thumb2 .play-btn {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); 
    }
      .play-thumb .play-btn p,
      .play-thumb2 .play-btn p {
        height: 160px;
        width: 160px;
        cursor: pointer;
        backdrop-filter: blur(30px);
        --webkit-backdrop-filter: blur(30px);
        background-color: rgba(255, 255, 255, 0.43);
        display: inline-block;
        text-align: center;
        line-height: 160px;
        border-radius: 50%;
        color: #fff;
        font-size: 26px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .play-thumb .play-btn p ,
          .play-thumb2 .play-btn p {
            height: 100px;
            width: 100px;
            line-height: 100px; } }
        @media (max-width: 767px) {
          .play-thumb .play-btn p ,
          .play-thumb2 .play-btn p {
            height: 100px;
            width: 100px;
            line-height: 100px; } }
  