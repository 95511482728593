.carrers{
    align-items: center;
    margin-bottom: 60px;
    .form{
        label{
            color: #fff;
        }
        input{
            height: 40px;
            border-radius: 5px;
            margin-top: 5px;
            background: #dedede;
            text-transform: none;
            font-size: 18px;
        }
        textarea{
            border-radius: 5px;
            margin-top: 5px;
            background: #dedede;
            text-transform: none;
            font-size: 18px;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }
    .image-placeholder {
        position: relative;
        max-width: 130px;
        margin: 10px auto;
      }
      .image-placeholder .avatar-edit {
        position: absolute;
        right: -10px;
        z-index: 1;
        top: 0px;
      }
      .image-placeholder .avatar-edit input {
        display: none;
      }
      .image-placeholder .avatar-edit input + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
      }
      .image-placeholder .avatar-edit input + label:hover {
        background: #f1f1f1;
        border-color: #d6d6d6;
      }
      .image-placeholder .avatar-edit input + label:after {
        content: "\f040";
        font-family: "FontAwesome";
        color: #757575;
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
      }
      .image-placeholder .avatar-preview {
        width: 130px;
        height: 95px;
        position: relative;
        border-radius: 5px;
        border: 3px solid #f8f8f8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      }
      .image-placeholder .avatar-preview > div {
        width: 100%;
        height: 100%;
        /* border-radius: 30px; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        display: flex;
          align-items: center;
          justify-content: center;
      }
      .image-placeholder .avatar-preview > div img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
        object-fit: fill;
      }
}
.file-upload{
    display:block;
    text-align:center;

    .file-select{
        display:block;
        border-radius: 5px;
        color: #34495e;
        cursor:pointer;
        height:40px;
        line-height:40px;
        text-align:left;
        background:#FFFFFF;
        overflow:hidden;
        position:relative;
        margin-top: 5px;

        .file-select-button{
            background:#dce4ec;
            padding:0 10px;
            display:inline-block;
            height:40px;
            line-height:40px;
        }
    }
}

.file-upload .file-select .file-select-name{line-height:40px;display:inline-block;padding:0 10px;}
.file-upload .file-select:hover{border-color:#34495e;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
.file-upload .file-select:hover .file-select-button{background:#34495e;color:#FFFFFF;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
.file-upload.active .file-select{border-color:#3fa46a;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
.file-upload.active .file-select .file-select-button{background:#3fa46a;color:#FFFFFF;transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;}
.file-upload .file-select input[type=file]{z-index:100;cursor:pointer;position:absolute;height:100%;width:100%;top:0;left:0;opacity:0;filter:alpha(opacity=0);}
.file-upload .file-select.file-select-disabled{opacity:0.65;}
.file-upload .file-select.file-select-disabled:hover{cursor:default;display:block;border: 2px solid #dce4ec;color: #34495e;cursor:pointer;height:40px;line-height:40px;margin-top:5px;text-align:left;background:#FFFFFF;overflow:hidden;position:relative;}
.file-upload .file-select.file-select-disabled:hover .file-select-button{background:#dce4ec;color:#666666;padding:0 10px;display:inline-block;height:40px;line-height:40px;}
.file-upload .file-select.file-select-disabled:hover .file-select-name{line-height:40px;display:inline-block;padding:0 10px;}