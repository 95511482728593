/*===========================
    5.ABOUT css 
===========================*/
.about-area {
    padding-top: 148px;
    position: relative;
}

.section-title .title {
    font-size: 76px;
    line-height: 86px;
    text-transform: capitalize;
    margin: 0;
}
    .about-area .section-title {
      margin-bottom: 55px; }
    .about-area .about-shape-2 {
      position: absolute;
      right: 170px;
      top: 130px;
      z-index: -1; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-area .about-shape-2 {
          display: none; } }
      @media (max-width: 767px) {
        .about-area .about-shape-2 {
          display: none; } }
    .about-area.about-6-area {
      padding-top: 147px; }
  
  .about-item {
    background: #fff;
    padding: 35px 150px 22px 40px;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
      .about-item {
        padding: 35px 100px 22px 40px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .about-item {
        padding: 35px 120px 22px 40px; } }
    @media (max-width: 767px) {
      .about-item {
        padding: 30px 30px 50px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .about-item {
        padding: 35px 120px 22px 40px; } }
    .about-item .title {
      font-size: 40px;
      color: #000;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      @media (max-width: 767px) {
        .about-item .title {
          font-size: 30px; } }
    .about-item p {
      display: inline-block;
      color: #464646;
      font-size: 20px;
      font-family: 'circular-5';
      line-height: 27px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      @media (max-width: 767px) {
        .about-item p {
          font-size: 18px; } }
    .about-item .icon {
      position: absolute;
      right: 20px;
      bottom: -10px; }
    .about-item.active, .about-item:hover {
      background: #6366EA; }
      .about-item.active .title, .about-item:hover .title {
        color: #fff; }
      .about-item.active p, .about-item:hover p {
        color: #fff; }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .about-item.item-3 {
        display: none; } }
  
  .about-text {
    padding-top: 65px; }
    .about-text p {
      font-size: 33px;
      margin-bottom: 6rem;
      line-height: 56px; }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .about-text p {
          font-size: 24px;
          line-height: 44px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-text p {
          font-size: 20px;
          line-height: 40px; } }
      @media (max-width: 767px) {
        .about-text p {
          font-size: 18px;
          line-height: 30px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .about-text p {
          font-size: 22px;
          line-height: 36px; } }
  
  .about-top-tltle-area {
    position: relative;
    padding-top: 250px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-top-tltle-area .about-top-tltle-content {
        text-align: center; } }
    @media (max-width: 767px) {
      .about-top-tltle-area .about-top-tltle-content {
        text-align: center; } }
    .about-top-tltle-area .about-top-tltle-content span {
      color: #6366EA; }
    .about-top-tltle-area .about-top-tltle-content .title {
      font-size: 76px;
      line-height: 86px;
      padding-top: 20px;
      padding-right: 50px;
      text-transform: capitalize; }
      @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .about-top-tltle-area .about-top-tltle-content .title {
          font-size: 60px;
          line-height: 70px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .about-top-tltle-area .about-top-tltle-content .title {
          font-size: 54px;
          line-height: 64px;
          padding-right: 0px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-top-tltle-area .about-top-tltle-content .title {
          font-size: 50px;
          line-height: 60px;
          padding-right: 0px; } }
      @media (max-width: 767px) {
        .about-top-tltle-area .about-top-tltle-content .title {
          font-size: 24px;
          line-height: 34px;
          padding-right: 0px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .about-top-tltle-area .about-top-tltle-content .title {
          font-size: 38px;
          line-height: 48px;
          padding-right: 0px; } }
    .about-top-tltle-area .about-shape {
      position: absolute;
      right: 310px;
      top: 70%;
      transform: translateY(-50%); }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .about-top-tltle-area .about-shape {
          right: 100px;
          top: 85%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-top-tltle-area .about-shape {
          display: none; } }
      @media (max-width: 767px) {
        .about-top-tltle-area .about-shape {
          display: none; } }
  
  .about-thumb {
    padding-top: 125px; }
  
  .about-service-area {
    padding-top: 107px;
    padding-bottom: 137px;
    position: relative; }
    .about-service-area .about-service-shape {
      position: absolute;
      left: 160px;
      top: 400px;
      transform: rotate(-45deg); }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-service-area .about-service-shape {
          display: none; } }
      @media (max-width: 767px) {
        .about-service-area .about-service-shape {
          display: none; } }
    .about-service-area .section-title {
      margin-top: 40px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-service-area .section-title {
          text-align: center; } }
      .about-service-area .section-title .title {
        font-size: 66px;
        line-height: 76px; }
        @media only screen and (min-width: 1200px) and (max-width: 1600px) {
          .about-service-area .section-title .title {
            font-size: 54px;
            line-height: 64px; } }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          .about-service-area .section-title .title {
            font-size: 40px;
            line-height: 50px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .about-service-area .section-title .title {
            font-size: 40px;
            line-height: 50px; } }
        @media (max-width: 767px) {
          .about-service-area .section-title .title {
            font-size: 28px;
            line-height: 40px; } }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          .about-service-area .section-title .title {
            font-size: 28px;
            line-height: 40px; } }
  
  .about-service-item {
    margin-top: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
      .about-service-item {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .about-service-item {
        text-align: center; } }
    .about-service-item h3 {
      font-size: 42px;
      color: #6366EA;
      position: relative;
      margin-bottom: 10px; }
      .about-service-item h3::before {
        position: absolute;
        content: '';
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 80%;
        background: rgba(0, 0, 0, 0.12); }
        @media only screen and (min-width: 1200px) and (max-width: 1600px) {
          .about-service-item h3::before {
            width: 75%; } }
        @media only screen and (min-width: 1200px) and (max-width: 1600px) {
          .about-service-item h3::before {
            width: 75%; } }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          .about-service-item h3::before {
            width: 70%; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .about-service-item h3::before {
            display: none; } }
        @media (max-width: 767px) {
          .about-service-item h3::before {
            display: none; } }
    .about-service-item .title {
      text-transform: capitalize;
      font-size: 40px;
      margin-bottom: 8px; }
      @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .about-service-item .title {
          font-size: 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .about-service-item .title {
          font-size: 24px; } }
      @media (max-width: 767px) {
        .about-service-item .title {
          font-size: 30px; } }
  
  .about-relationship-area {
    background: #f3f3f3;
    padding: 65px 0;
    position: relative; }
    .about-relationship-area .bg-image {
    //   background-image: url(../images/hero-bg-4.jpg);
      left: 0;
      top: 0;
      width: 50%; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .about-relationship-area .bg-image {
          width: 0; } }
      @media (max-width: 767px) {
        .about-relationship-area .bg-image {
          width: 0; } }
  
  .about-relationship-content {
    padding-left: 50px; }
    @media only screen and (min-width: 1200px) and (max-width: 1600px) {
      .about-relationship-content {
        padding-left: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .about-relationship-content {
        padding-left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about-relationship-content {
        padding-left: 0px; } }
    @media (max-width: 767px) {
      .about-relationship-content {
        padding-left: 0px; } }
    .about-relationship-content .title {
      font-size: 44px;
      line-height: 66px;
      padding-right: 20px;
      margin-bottom: 23px; }
      @media only screen and (min-width: 1200px) and (max-width: 1600px) {
        .about-relationship-content .title {
          font-size: 35px;
          line-height: 45px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .about-relationship-content .title {
          font-size: 30px;
          line-height: 40px; } }
      @media (max-width: 767px) {
        .about-relationship-content .title {
          font-size: 30px;
          line-height: 40px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .about-relationship-content .title {
          font-size: 36px;
          line-height: 46px; } }
    .about-relationship-content .user {
      padding-top: 17px; }
      .about-relationship-content .user h5 {
        font-size: 44px;
        color: #6366EA;
        margin-bottom: 0; }
      .about-relationship-content .user span {
        font-size: 18px;
        font-family: 'circular-5';
        text-transform: uppercase;
        color: #838383; }
  