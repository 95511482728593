/*===========================
    3.HREO css 
===========================*/
.hero-area {
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media #{$xs} {
        height: 700px;
    }

    &::before {
        position: absolute;
        content: 'Marketing';
        right: -285px;
        top: 325px;
        font-size: 110px;
        color: #000;
        opacity: 0.1;
        font-family: 'circular-5';
        transform: rotate(-90deg);
        text-transform: uppercase;
        z-index: 2;

    }

    & .hero-thumb {
        position: absolute;
        right: 285px;
        top: 285px;
        z-index: 1;

        @media #{$laptop} {
            right: 100px;
            top: 50%;
            transform: translateY(-50%);
            width: 300px;
        }

        @media #{$lg} {
            top: 50%;
            right: 130px;
            width: 240px;
            transform: translateY(-50%);
        }

        @media #{$md} {
            display: none;
        }

        @media #{$xs} {
            display: none;
        }

        & img {
            transition: all .8s ease-out 0s;

            &:hover {
                transform: scale(1.2);
                transition-timing-function: cubic-bezier(0.47, 2.02, .31, -.36);
            }
        }
    }
    & .hero-shape{
        position: absolute;
        right: 510px;
        bottom: 0;
    }

    & .hero-scroll {
        position: absolute;
        right: 200px;
        bottom: 80px;
        z-index: 3;

        @media #{$xs} {
            display: none;
        }

        & .scroll {
            text-align: center;

            & span {
                display: block;
                font-size: 18px;
                color: $black;
                font-family: 'circular-5';
                line-height: 22px;
            }
        }

    }

    &.hero-2-area {
        & .hero-bg {
            // background-image: url(../images/hero-bg.jpg);
            height: 580px;
            width: 1180px;
            left: auto;
            right: 200px;

            @media #{$laptop} {
                display: none;
            }            
            @media #{$lg} {
                display: none;
            }     
            @media #{$md} {
                display: none;
            }     
            @media #{$xs} {
                display: none;
            }
        }

        & .hero-content {
            & .title {
                // background: url(../images/text-bg.jpg) no-repeat;
                background-size: cover;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                background-position: 66% 100%;
                @media #{$laptop} {
                    background: none;
                    -webkit-text-fill-color: $black;
                    -webkit-background-clip: inherit;
                }
                @media #{$lg} {
                    background: none;
                    -webkit-text-fill-color: $black;
                    -webkit-background-clip: inherit;
                }
                @media #{$md} {
                    background: none;
                    -webkit-text-fill-color: $black;
                    -webkit-background-clip: inherit;
                }
                @media #{$xs} {
                    background: none;
                    -webkit-text-fill-color: $black;
                    -webkit-background-clip: inherit;
                }


            }
        }
    }
    &.hero-style-6{
        position: relative;
        height: auto;
        padding-top: 200px;
        padding-bottom: 200px;
        @media #{$xs} {
            padding-bottom: 100px;
        }
        &::before{
            display: none;
        }
        &::after{
            position: absolute;
            content: '';
            height: 100%;
            width: 76%;
            background: #F7F7F7;
            left: 0;
            top: 0;
            right: auto;
            opacity: 1;
            z-index: -1;
            @media #{$md} {
                width: 100%;
            }
            @media #{$xs} {
                width: 100%;
            }
        }
        & .hero-thumb-6{
            margin-left: 150px;
            @media #{$laptop} {
                margin-left: 50px;
            }
            @media #{$lg} {
                margin-left: 50px;
            }
            @media #{$md} {
                margin-left: 0px;
                margin-top: 40px;
            }
            @media #{$xs} {
                margin-left: 0px;
                margin-top: 40px;
            }
            & img{
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            }
        }
        & .hero-content{
            & a{
                margin: 0;
                padding: 0;
                line-height: 70px;
                padding: 0 40px;
                background: $theme-color;
                color: $white;
                border: 4px solid $theme-color;
                margin-top: 30px;
                &:hover{
                    background-color: transparent;
                    color: $theme-color;
                }
                &::after{
                    display: none;
                }
                &::before{
                    display: none;
                }
            }
        }
    }
    &.hero-7-area{
        @media #{$lg}{
            height: 800px;
        }
        @media #{$md}{
            height: 800px;
        }
        @media #{$xs}{
            height: 700px;
        }
        &::before{
            display: none;
        }
        &::after{
            position: absolute;
            content: '';
            height: 100%;
            width: 40%;
            background: #EAE2E1;
            right: 0;
            top: 0;
            opacity: 1;
            z-index: -1;
            @media #{$laptop}{
                width: 50%;
            }
            @media #{$md}{
                width: 0%;
            }
            @media #{$xs}{
                width: 0%;
            }
        }
        & .hero-7-thumb{
            position: absolute;
            right: 50px;
            bottom: 0;
            width: 570px;
            @media #{$lg}{
                width: 350px;
            }
            @media #{$md}{
                display: none;
            }
            @media #{$xs}{
                display: none;
            }
        }
        & .hero-content{
            & .title{
                @media #{$laptop}{
                    font-size: 100px;
                    line-height: 110px;
                }
            }
        }
    }
}








.hero-content {
    & .title {
        font-size: 134px;
        line-height: 144px;
        margin-bottom: 2px;

        @media #{$laptop} {
            font-size: 120px;
            line-height: 130px;
        }

        @media #{$lg} {
            font-size: 100px;
            line-height: 110px;
        }

        @media #{$md} {
            font-size: 90px;
            line-height: 100px;
        }

        @media #{$xs} {
            font-size: 50px;
            line-height: 60px;
        }

        @media #{$sm} {
            font-size: 70px;
            line-height: 80px;
        }
    }

    & p {
        @media #{$lg} {
            font-size: 18px;
            line-height: 28px;
        }

        @media #{$xs} {
            font-size: 18px;
            line-height: 28px;
        }
    }

    & .get {
        font-size: 26px;
        font-family: 'circular-5';
        color: #6366EA;
        position: relative;
        padding-left: 25px;
        transition: all .6s ease-out 0s;
        margin-top: 53px;
        display: inline-block;
        line-height: 42px;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border: 2px solid #6366EA;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            z-index: -2;
        }

        &::after {
            position: absolute;
            content: '';
            left: 10px;
            top: 0;
            height: 100%;
            width: 100%;
            background: $white;
            z-index: -1;
        }

        &:hover {
            padding-left: 60px;
        }
    }
}




.hero-3-area{
    height: 1100px;
    @media #{$xs} {
        height: 800px;
    }
    & .hero-content{
        position: relative;
        z-index: 10;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: flex-end;
        height: 800px;
        @media #{$xs} {
            height: 500px;
        }
        & .hero-bg{
            left: 0;
            right: 0;
            // background-image: url(../images/hero-bg-3.jpg);
            z-index: -1;
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
                background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
            }
        }
        & .title{
            color: $white;
            padding-left: 55px;
            padding-bottom: 30px;
            font-size: 105px;
            line-height: 115px;
            text-transform: capitalize;
            @media #{$lg} {
                font-size: 80px;
                line-height: 90px;
            }
            @media #{$md} {
                font-size: 60px;
                line-height: 70px;
                padding-left: 30px;
            }
            @media #{$xs} {
                font-size: 34px;
                line-height: 44px;
                padding-left: 20px;
            }
            @media #{$sm} {
                font-size: 40px;
                line-height: 50px;
                padding-left: 20px;
            }
        }
    }
    & .hero-scroll{
        z-index: 99;
        right: 215px;
        bottom: 170px;
        @media #{$laptop} {
            right: 30px;
        }
        @media #{$lg} {
            right: 40px;
        }
        @media #{$md} {
            right: 50px;
        }
        & a{
            & span{
                color: $white;
            }
        }
    }
}



@-webkit-keyframes hero-zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes hero-zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}





.hero-4-area{
    height: 1000px;
    position: relative;
    z-index: 10;
    overflow: hidden;
    & .hero-bg{
        // background-image: url(../images/hero-bg-4.jpg);
        z-index: -1;
        width: 100%;
        animation: 50s linear hero-zoom infinite;
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 95%);
        }
        & .hero-scroll {
            position: absolute;
            right: 30px;
            bottom: 30px;
            z-index: 3;

            @media #{$xs} {
                display: none;
            }

            & a {
                text-align: center;

                & span {
                    display: block;
                    font-size: 18px;
                    color: $white;
                    font-family: 'circular-5';
                    line-height: 22px;
                }
            }

        }
    }
    & .hero-content{
        color: $white;
        padding-top: 150px;
        @media #{$laptop} {
            font-size: 20px;
        }
        @media #{$md} {
            font-size: 20px;
        }
        @media #{$xs} {
            text-align: center;
        }
        & .title{
            font-size: 80px;
            line-height: 90px;
            color: $white;
            text-transform: capitalize;
            @media #{$laptop} {
                font-size: 68px;
                line-height: 78px;
            }
            @media #{$lg} {
                font-size: 54px;
                line-height: 64px;
            }
            @media #{$md} {
                font-size: 58px;
                line-height: 68px;
            }
            @media #{$xs} {
                font-size: 32px;
                line-height: 42px;
            }
            @media #{$sm} {
                font-size: 46px;
                line-height: 56px;
            }
        }
        & p{
            padding-right: 140px;
            padding-bottom: 22px;
            padding-top: 17px;
            @media #{$laptop} {
                padding-right: 0;
            }
            @media #{$lg} {
                padding-right: 0;
            }
            @media #{$md} {
                padding-right: 0;
            }
            @media #{$xs} {
                padding-right: 0;
            }
        }
        & .get{
            background: transparent;
            color: $white;
            padding-left: 0;
            margin-top: 0;
            border: 5px solid #fff;
            display: inline-block;
            line-height: 74px;
            padding: 0 45px;
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
            &:hover{
                background: $white;
                color: $theme-color;
            }
        }
    }
}






.solution-area{
    & .solution-thumb{
        @media #{$md} {
            text-align: center;
        }
        @media #{$xs} {
            text-align: center;
        }
    }
    & .solution-title{
        margin-left: -300px;
        @media #{$lg} {
            margin-left: -210px;
        }
        @media #{$md} {
            margin-left: 0px;
            text-align: center;
        }
        @media #{$xs} {
            margin-left: 0px;
            text-align: center;
        }
        & .title{
            font-size: 76px;
            line-height: 86px;
            // background: url(../images/text-bg-2.jpg) no-repeat;
            background-size: 116% 100%;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            background-position: 100% 100%;
            @media #{$laptop} {
                background-size: 109% 100%;
            }
            @media #{$lg} {
                font-size: 58px;
                line-height: 68px;
                background-size: 114% 100%;
            }
            @media #{$md} {
                -webkit-text-fill-color: #000;
                font-size: 50px;
                line-height: 60px;
                padding-top: 30px;
            }
            @media #{$xs} {
                -webkit-text-fill-color: #000;
                font-size: 32px;
                line-height: 42px;
                padding-top: 30px;
            }
            @media #{$sm} {
                -webkit-text-fill-color: #000;
                font-size: 50px;
                line-height: 60px;
            }
            
        }
    }
}








.page-title-area{
    height: 730px;
    position: relative;
    @media #{$md} {
        height: 550px;
    }
    @media #{$xs} {
        height: 550px;
    }
    .bg-image{
        // background-image: url(../images/page-bg.jpg);
        left: 0;
        top: 0;
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
        }
    }
    & .page-title-content{
        & .title{
            font-size: 76px;
            line-height: 86px;
            color: $white;
            @media #{$lg} {
                font-size: 58px;
                line-height: 68px;
            }
            @media #{$md} {
                font-size: 56px;
                line-height: 66px;
                text-align: center;
            }
            @media #{$xs} {
                font-size: 30px;
                line-height: 40px;
                text-align: center;
            }
            @media #{$sm} {
                font-size: 45px;
                line-height: 55px;
                text-align: center;
            }
        }
    }
    &.blog-page-title-area{
        background: $black;
        height: 590px;
        @media #{$xs} {
            height: 450px;
        }
        & .bg-image{
            display: none;
        }
        & .page-title-content{
            color: $white;
            & .title{
                margin-bottom: 10px;
            }
        }
    }
    &.contact-page-title-area{
        background: #F2F2F2;
        height: 740px;
        & .bg-image{
            display: none;
        }
        & .page-title-content{
            & .title{
                color: $black;
            }
        }
    }
    &.blog-details-page-area{
        background: $white;
        height: 860px;
        @media #{$lg} {
            height: 600px;
        }
        @media #{$md} {
            height: 600px;
        }
        @media #{$xs} {
            height: 500px;
        }
        & .bg-image{
            display: none;
        }
        & .page-title-content{
            & .title{
                color: $black;
                @media #{$laptop} {
                    font-size: 60px;
                }
                @media #{$lg} {
                    font-size: 48px;
                }
            }
        }
    }
}