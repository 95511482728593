/* -------------------------------------------

preloader

------------------------------------------- */
.mil-preloader {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #303D57;

    & .mil-preloader-animation {
        opacity: 0;
        position: relative;
        height: 100vh;
        color: $light;

        & .mil-pos-abs {
            position: absolute;
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            & p {
                opacity: 0;
                margin-right: 15px;
            }

            @media screen and (max-width: 992px) {
                flex-direction: column;

                & p {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            & .mil-reveal-frame {
                position: relative;
                padding: 0 30px;

                & .mil-reveal-box {
                    z-index: 4;
                    position: absolute;
                    opacity: 0;
                    height: 100%;
                    background-color: $accent;
                }
            }
        }
    }

    &.mil-hidden {
        pointer-events: none;
    }
}

/* -------------------------------------------

cursor

------------------------------------------- */
.mil-ball {
    width: 20px;
    height: 20px;
    position: fixed;
    z-index: 10;
    background-color: $dark;
    border-radius: 50%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .1;

    & .mil-icon-1 {
        position: absolute;
        width: 40px;
        height: 40px;
        transform: scale(0);

        & svg {
            fill: $lt-90;
        }
    }

    & .mil-more-text,
    & .mil-choose-text {
        position: absolute;
        width: 100%;
        text-align: center;
        display: block;
        color: $lt-90;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        transform: scale(0);
    }

    &.mil-accent {
        & .mil-icon-1 {
            & svg {
                fill: $dark;
            }
        }

        & .mil-more-text,
        & .mil-choose-text {
            color: $dark;
        }
    }

    @media screen and (max-width: 1200px) {
        display: none;
    }
}

/* -------------------------------------------

hidden elements

------------------------------------------- */

.mil-hidden-elements {

    & .mil-dodecahedron,
    & .mil-lines,
    & .mil-arrow {
        display: none;
    }
}

/* -------------------------------------------

scrollbar

------------------------------------------- */
::-webkit-scrollbar {
    display: none;
}

.mil-progress-track {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: $dark;

    & .mil-progress {
        width: 4px;
        height: 0;
        background-color: $accent;
    }

    @media screen and (max-width: 992px) {
        display: none;
    }
}

/* -------------------------------------------

frame

------------------------------------------- */

.mil-logo {
    font-size: 42px;
    font-weight: 500;
    line-height: 100%;
    img{
        display: none;
    }

    @media screen and (max-width: 1200px) {
        img{
            display: block;
        }
    }
    @media screen and (max-width: 992px) {
        font-size: 36px;
    }
}

.mil-frame {
    padding: 50px 60px 60px 60px;
    position: fixed;
    z-index: 2;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1200px) {
        padding: 30px;
    }

    @media screen and (max-width: 1200px) {
        padding: 0;
        z-index: 999;
        height: 90px;
    }

    & .mil-frame-top {
        @media screen and (max-width: 1200px) {
            height: 90px;
            background-color: #303D57;
            border-bottom: solid 1px $lt-10;
            padding: 0 30px;
            backdrop-filter: blur(8px);

            & .mil-logo {
                color: $light;
            }

            & .mil-menu-btn {

                & span,
                & span:after,
                & span:before {
                    background: $light;
                }
            }

        }
    }

    & .mil-frame-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media screen and (max-width: 1200px) {
            display: none;
        }

        & .mil-current-page {
            pointer-events: none;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 12px;
            width: 300px;
            color: $dark;
            transform: rotate(-90deg) translateX(138px) translateY(-138px);
        }

        & .mil-back-to-top {
            width: 300px;
            transform: rotate(-90deg) translateX(130px) translateY(130px);
            pointer-events: all;
        }
    }
}

.mil-frame-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .mil-logo {
        pointer-events: all;
        color: $dark;
    }
}

/* -------------------------------------------

menu button

------------------------------------------- */
.mil-menu-btn {
    pointer-events: all;
    height: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $t-md;

    @media screen and (max-width: 992px) {
        transform: scale(0.85);
    }


    & span,
    & span:after,
    & span:before {
        content: "";
        display: block;
        width: 28px;
        height: 2.5px;
        background: $dark;
        backface-visibility: hidden;
        transition: inherit;
    }

    & span {
        position: relative;

        &:after,
        &:before {
            position: absolute;
        }

        &:before {
            top: -9px;
        }

        &:after {
            width: 18px;
            top: 9px;
        }
    }

    &.mil-active {
        & span {
            transform: rotate(45deg);

            &:before {
                transform: translate(0px, 9px) rotate(-90deg);
            }

            &:after {
                opacity: 0;
                width: 24px;
                transform: translate(0px, -9px) rotate(-90deg);
            }
        }
    }
}

/* -------------------------------------------

menu

------------------------------------------- */
.mil-menu-frame {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    background-color: #303d57;
    opacity: 0;
    pointer-events: none;
    transition: $t-md;

    & .container {
        pointer-events: none;
    }

    & .mil-frame-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 50px 60px;

        @media screen and (max-width: 1200px) {
            display: none;
        }

        & .mil-logo {
            color: $light;
        }

        & .mil-menu-btn {

            & span,
            & span:after,
            & span:before {
                background-color: $light;
            }
        }
    }

    & .mil-main-menu {
        transform: translateX(-30px);
        opacity: 0;
        transition: $t-md;

        @media screen and (max-width: 1200px) {
            transform: translateY(30px);
        }
    }

    & .mil-menu-right-frame {
        padding-left: 60px;
        position: relative;
        height: 100vh;
        display: flex;
        align-items: flex-end;
        border-left: solid 1px $lt-10;
        opacity: 0;
        transform: translateY(30px);
        transition: $t-md;

        & .mil-menu-right {
            padding-bottom: 60px;
        }

        & .mil-animation-in {
            position: absolute;
            top: -320px;
            right: 0;
            opacity: 0;
            transform: translateY(-60px);
            transition: $t-md;
        }

        @media screen and (max-width: 1200px) {
            display: none;
        }

        @media screen and (max-height: 800px) {
            display: none;
        }
    }

    &.mil-active {
        opacity: 1;
        pointer-events: all;

        & .container {
            pointer-events: all;
        }

        & .mil-main-menu {
            transform: translateX(0);
            transform: translateY(0);
            opacity: 1;
            transition-delay: .4s;
        }

        & .mil-menu-right-frame {
            opacity: 1;
            transform: translateY(0);
            transition-delay: .6s;

            & .mil-animation-in {
                opacity: 1;
                transform: translateY(0);
                transition-delay: .8s;
            }
        }
    }
}

.mil-menu-list {
    & li {
        list-style-type: none;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        & a {
            display: block;
            transition: $t-sm;

            &:hover {
                transform: translateX(5px);
                color: $light;
            }
        }
    }

    &.mil-hori-list {
        display: flex;

        & li {
            margin-bottom: 0;
            margin-right: 30px;
        }
    }

    &.mil-dark {
        & li {
            & a {
                color: $dark;
            }
        }
    }
}


.mil-main-menu {
    height: 100vh;
    display: flex;
    align-items: center;

    ul {
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                position: relative;
                display: flex;
                align-items: center;
                font-weight: 500;
                color: $lt-80;
                font-size: $h4 + 6px;
                transition: $t-md;

                @media screen and (max-width: 992px) {
                    font-size: $h5 + 6px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $lt-40;
                    transform: scale(0);
                    transition: $t-md;
                }

                &:hover {
                    color: $light;
                }

                &.mil-active {
                    padding-left: 25px;

                    &:before {
                        transform: scale(1);
                        background-color: $accent;
                    }
                }
            }

            &.mil-active {
                & > a {
                    color: $accent;

                    &:hover {
                        color: $accent !important;
                        filter: brightness(110%);
                    }
                }
            }

            &.mil-has-children {
                & > a {
                    &:hover {
                        color: $light;
                        padding-left: 25px;

                        &:before {
                            transform: scale(1);
                        }
                    }
                }

                ul {
                    padding-left: 25px;
                    overflow: hidden;
                    max-height: 0;
                    transition: $t-lg;

                    li {
                        margin-bottom: 5px;

                        &:first-child {
                            margin-top: 40px;
                        }

                        a {
                            display: block;
                            font-size: 12px;
                            color: $lt-40;
                            letter-spacing: 2px;
                            font-weight: 500;
                            text-transform: uppercase;

                            &:before {
                                display: none;
                            }

                            &:hover {
                                color: $lt-80;
                                transform: translateX(5px);
                            }
                        }
                    }

                    &.mil-active {
                        max-height: 200px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: center;

        & ul {
            display: flex;
            flex-direction: column;
            align-items: center;

            & li {
                margin-bottom: 30px;

                & a {
                    justify-content: center;

                    &:before {
                        display: none;
                    }

                    &.mil-active {
                        padding-left: 0 !important;
                    }
                }

                &.mil-has-children {

                    ul {
                        padding-left: 0;

                        & li {
                            &:first-child {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* -------------------------------------------

banner

------------------------------------------- */
.mil-banner {
    height: 100vh;

    & .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    & .mil-banner-content {
        width: 100%;
        padding-bottom: 120px;
        position: relative;

        @media screen and (max-width: 992px) {
            padding-bottom: 90px;
        }
    }

    & .mil-lines-place {
        position: absolute;
        left: 0;
        bottom: calc(100% + 120px);
    }
}

.mil-inner-banner {
    & .mil-banner-content {
        padding: 150px 0 0 0;

        @media screen and (max-width: 1200px) {
            padding: 180px 0 0 0;
        }
        @media screen and (max-width: 500px) {
            padding: 90px 0 0 0;
        }
    }
}

.mil-banner-personal {
    height: 100vh;
    background-color: $dt-10;

    & .mil-banner-content {
        position: relative;
        padding-top: 90px;

        @media screen and (max-width: 992px) {
            padding-top: 180px;
        }

        & .mil-personal-text {
            margin-bottom: 300px;

            @media screen and (max-width: 992px) {
                margin-bottom: 80px;
                text-align: center;
            }
        }

        & .mil-banner-panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 60px 0 90px;
            background-color: $light;
            height: 180px;
            position: absolute;
            top: calc(100vh - 180px);
            left: 0;
            width: 100%;

            @media screen and (max-width: 992px) {
                flex-direction: column;
                height: auto;
                padding: 60px 30px;

                & h5 {
                    margin-bottom: 60px;
                    text-align: center;
                    width: 80%;
                }
            }

            & .mil-right {
                display: flex;

                & .mil-social-frame {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 30px;
                    padding: 0 50px;
                    background-color: $dt-10;
                    height: 70px;
                    border-radius: 70px;
                    transition: $t-md;

                    &:hover {
                        transform: scale(1.015);
                    }
                }

                @media screen and (max-width: 992px) {
                    flex-direction: column;

                    & .mil-social-frame {
                        margin-right: 0;
                        margin-bottom: 30px;
                        height: 60px;
                    }
                }
            }

            & .mil-button {
                margin: 0 !important;
            }

            @media screen and (max-width: 992px) {
                bottom: 0;
                top: auto;
            }

            @media screen and (max-width: 768px) {
                position: static;
                margin-bottom: 90px;
            }
        }
    }

    @media screen and (max-width: 992px) {
        height: auto;
    }
}


.mil-portrait-frame {
    position: relative;

    & img {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    & .mil-nimbus {
        background: radial-gradient(50% 50% at 50% 50%, $accent 0%, rgba(250, 168, 69, 0) 100%);
        width: 100%;
        padding-bottom: 100%;
        position: absolute;
        opacity: .6;
    }

    @media screen and (max-width: 768px) {
        height: 400px;

        & img {
            object-fit: cover;
            object-position: top;
        }
    }
}


/* -------------------------------------------

circle text

------------------------------------------- */

.mil-circle-text {
    position: absolute;
    right: 0;
    bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;

    & .mil-ct-svg {
        transform: scale(2);
        width: 140px;
        height: 140px;

        & text {
            fill: $lt-40;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            transition: $t-md;
        }
    }

    & .mil-button {
        position: absolute;
    }

    &:hover {
        & svg {
            & text {
                fill: $light;
            }
        }
    }

    @media screen and (max-width: 992px) {
        display: none;
    }
}

/* -------------------------------------------

lines

------------------------------------------- */
@mixin move-animation {
    from {
        transform: translateY(-75px);
    }

    // 50% {
    //     transform: translateY(75px);
    // }

    to {
        transform: translateY(75px);
    }
}

// @keyframes move {
//     @include move-animation;
// }

.mil-lines-place {
    & .mil-lines {
        opacity: 0.05;
        pointer-events: none;

        @media screen and (max-width: 992px) {
            display: none;
        }

        & path,
        & rect,
        & line {
            stroke: $dark;
        }

        & .mil-move {
            animation: move 10s linear infinite;
        }
    }

    &.mil-light {
        & .mil-lines {
            opacity: .2;

            & path,
            & rect,
            & line {
                stroke: $light;
            }
        }
    }
}

/* -------------------------------------------

dodecahedron

------------------------------------------- */
.mil-animation-frame {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    animation: jump 10s linear infinite;
    pointer-events: none;
}

.mil-animation {
    position: absolute;
    display: inline-block;
    width: 300px;
    height: 300px;
    opacity: .2;
    pointer-events: none;
}

.mil-position-1 {
    top: 100px;
    right: 100px;

    & .mil-pentagon {
        & div {
            border-top: .1px solid $light;
        }
    }
}

.mil-position-2 {
    top: -60px;
    left: 15%;

    & .mil-pentagon {
        & div {
            border-top: 1px solid $light;
        }
    }

    & .mil-dodecahedron {
        animation-delay: .5s;
    }

    @media screen and (max-width: 1400px) {
        display: none;
    }
}

.mil-position-3 {
    bottom: -100px;
    right: 35%;

    & .mil-pentagon {
        & div {
            border-top: 1px solid $light;
        }
    }

    & .mil-dodecahedron {
        animation-delay: 1s;
    }

    @media screen and (max-width: 1400px) {
        display: none;
    }
}

.mil-position-4 {
    top: -60px;
    right: 20%;

    & .mil-pentagon {
        & div {
            border-top: .1px solid $light;
        }
    }

    & .mil-dodecahedron {
        animation-delay: .5s;
    }

    @media screen and (max-width: 1400px) {
        display: none;
    }

    &.mil-dark {
        & .mil-pentagon {
            & div {
                border-top: .1px solid $dark;
            }
        }
    }
}

.mil-dodecahedron {
    position: relative;
    left: 100px;
    top: 40px;
    width: 100px;
    height: 223px;
    transform-style: preserve-3d;
    animation: rotate 100s infinite linear;

    @keyframes rotate {
        from {
            transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
        }

        to {
            transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
        }
    }
}

.mil-pentagon {
    position: absolute;
    width: 100px;

    @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
            transform: rotateY(#{$i * 1/5}turn) translateZ(69px) rotateX(26.5deg);
        }

        &:nth-child(#{$i+5}) {
            bottom: 0;
            transform: rotateY(#{$i * 1/5}turn) translateZ(-69px) rotateX(206.5deg);
        }
    }

    &:nth-child(11) {
        transform: translateZ(69px) rotateX(-90deg);
    }

    &:nth-child(12) {
        bottom: 0;
        transform: translateZ(-69px) rotateX(90deg);
    }

    & div {
        position: absolute;
        width: 100px;
        height: 69px;
        transform-origin: bottom;

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                transform:rotate(#{$i * 1/5}turn)
            }
        }
    }
}

@keyframes jump {
    0% {
        transform: translateY(-30px);
    }

    50% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(-30px);
    }
}

/* -------------------------------------------

about

------------------------------------------- */

.mil-about-quote {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .mil-avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: relative;

        &:after {
            content: ' " ';
            color: $dark;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $accent;
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            padding-top: 12px;
            justify-content: center;
            font-size: 36px;
        }

        & img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    & .mil-quote {
        padding-left: 30px;
        width: calc(100% - 90px);
    }

    @media screen and (max-width: 992px) {
        & .mil-avatar {
            width: 70px;
            height: 70px;

            &:after {
                padding-top: 7px;
                width: 25px;
                height: 25px;
                font-size: 24px;
            }
        }

        & .mil-quote {
            font-size: 16px;
            padding-left: 30px;
            width: calc(100% - 70px);
        }
    }
}

.mil-about-photo {
    position: relative;

    & .mil-lines-place {
        position: absolute;
        top: -120px;
        left: -27%;
    }
}

/* -------------------------------------------

partners

------------------------------------------- */
.mil-infinite-show {
    & .swiper-wrapper {
        transition-timing-function: linear;
    }
}

.mil-partner-frame {
    display: block;

    & img {
        width: 100%;
    }
}

/* -------------------------------------------

services

------------------------------------------- */
.mil-complex-text {
    display: flex;
    align-items: center;

    & .mil-button {
        margin-left: 30px;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        & .mil-button {
            margin-left: 0;
            margin-top: 60px;
        }
    }
}

.mil-text-image {
    height: 110px;
    width: 200px;
    display: inline-block;
    overflow: hidden;
    // border-radius: 70px;
    margin-right: 30px;

    & img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        object-position: top;
        transition: $t-md;
    }

    &:hover {
        & img {
            transform: scale(1.05);
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}


.mil-service-card-sm {
    position: relative;
    display: block;
    padding: 60px 30px;

    & p {
        opacity: 1;
        // transform: translateY(10px);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        // transition: $t-md;
    }

    & .mil-button {
        // transform: scale(.3);
        // filter: grayscale(100%);
        opacity: 1;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 0;
        background-color: $accent;
        transition: $t-lg;
    }

    &:hover {
        // & p {
        //     opacity: 1;
        //     transform: translateY(0);
        // }

        // & .mil-button {
        //     transform: scale(1);
        //     filter: grayscale(0);
        //     opacity: 1;
        // }

        &:before {
            width: 100%;
        }
    }

    @media screen and (max-width: 992px) {
        & p {
            opacity: 1;
            transform: translateY(0);
        }

        & .mil-button {
            transform: scale(1);
            filter: grayscale(0);
            opacity: 1;
        }
    }
}

.mil-services-grid {
    border-top: solid 1px $lt-10;

    & .mil-services-grid-item {
        border-right: solid 1px $lt-10;

        &:first-child {
            border-left: solid 1px $lt-10;
        }
    }

    @media screen and (max-width: 992px) {
        padding-bottom: 90px;

        & .mil-services-grid-item {
            border-bottom: solid 1px $lt-10;

            &:nth-child(3) {
                border-left: solid 1px $lt-10;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 90px;

        & .mil-services-grid-item {
            border-left: solid 1px $lt-10;
            border-bottom: solid 1px $lt-10;
        }
    }
}

.mil-service-card-lg {
    display: block;

    & .mil-descr {
        padding-right: 30px;
    }

    &.mil-offset {
        margin-top: 60px;
        margin-bottom: 60px;

        @media screen and (max-width: 992px) {
            margin-top: 0;
        }
    }

    @media screen and (max-width: 992px) {
        margin-bottom: 60px;
    }

    &.mil-other-card {
        overflow: hidden;
        position: relative;
        border: solid 1px $gs-10;
        padding: 60px;
        transition: $t-md;

        & .mil-descr {
            padding-right: 0;
        }

        &:hover {
            background-color: $accent;

            & .mil-link {
                & svg {
                    background-color: $dark;

                    & path {
                        fill: $accent;
                    }
                }
            }
        }

        @media screen and (max-width: 1200px) {
            padding: 30px;
        }
    }
}

.mil-service-list {
    & li {
        border-bottom: solid 1px $lt-10;
        list-style-type: none;
        padding: 15px 0;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 12px;

        &:first-child {
            border-top: solid 1px $lt-10;
        }
    }

    &.mil-light {
        & li {
            color: $lt-60;
        }
    }

    &.mil-dark {
        & li {
            border-bottom: solid 1px $dt-10;

            &:first-child {
                border-top: solid 1px $dt-10;
            }
        }
    }
}

/* -------------------------------------------

team

------------------------------------------- */

.mil-team-card {
    position: relative;
    overflow: hidden;
    padding-bottom: 130%;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 0;
        background-color: $accent;
        transition: $t-lg;
    }

    & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        transform: scale(1.05);
        transition: $t-md;
    }

    &.mil-offset-card {
        transform: translateY(60px);

        @media screen and (max-width: 992px) {
            transform: none;
        }
    }

    & .mil-description {
        opacity: 0;
        text-align: center;
        background-color: $dt-80;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 60px 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        pointer-events: none;
        transition: $t-lg;

        & .mil-secrc-text {
            opacity: 0;
            transform: translateY(15px);
            transition: $t-md;
            transition-delay: 0s;

            & a {
                color: $light;
                transition: $t-sm;

                &:hover {
                    color: $accent;
                }
            }
        }
    }

    &:hover {
        & img {
            transform: scale(1);
        }

        &:after {
            width: 100%;
        }

        & .mil-description {
            opacity: 1;
            pointer-events: all;

            & .mil-secrc-text {
                opacity: 1;
                transform: translateY(0);
                transition-delay: .1s;
            }
        }
    }
}

.mil-team-list {
    position: relative;

    & .mil-lines-place {
        position: absolute;
        top: -120px;
        left: -22.5%;
    }
}

/* -------------------------------------------

social icons

------------------------------------------- */

.mil-social-icons {
    padding: 0;
    margin: 0;
    display: flex;

    &.mil-center {
        justify-content: center;
    }

    & li {
        list-style-type: none;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }

        & a {
            color: $light;
            font-size: 18px;
            transition: $t-md;

            &:hover {
                color: $accent;
            }
        }
    }

    &.mil-dark {
        & li {
            & a {
                color: $dark;

                &:hover {
                    color: $accent;
                }
            }
        }
    }
    .social-icon{
        background: none;
        border: none;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
    }
}

/* -------------------------------------------

revievs

------------------------------------------- */
.mil-revi-pagination {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 992px) {
        flex-wrap: wrap;
    }

    & .swiper-pagination-bullet {
        padding: 0;
        width: 110px;
        height: 110px;
        padding: 10px;
        opacity: 1;
        border: none;
        background-color: transparent;
        margin: 0 !important;
        transition: $t-md !important;

        @media screen and (max-width: 992px) {
            width: 90px;
            height: 90px;
        }

        &:nth-child(2n) {
            margin-top: 30px !important;

            @media screen and (max-width: 992px) {
                margin-top: 0 !important;
            }
        }

        &:hover {
            box-shadow: inset 0 0 0 4px $light;
        }

        & .mil-custom-dot {
            background-color: red;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-size: cover;
            background-position: top;
            transition: $t-md !important;

            @media screen and (max-width: 992px) {
                width: 70px;
                height: 70px;
            }
        }

        &.swiper-pagination-bullet-active {

            box-shadow: inset 0 0 0 4px $accent;
        }
    }

}

.mil-quote-icon {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    margin-bottom: 15px;

    & path {
        fill: $accent;
    }
}


.mil-slider-nav {
    display: flex;

    & .mil-slider-arrow {
        width: 40px;
        height: 40px;
        padding: 10px;
        background-color: $dark;
        border-radius: 50%;
        cursor: pointer;
        transition: $t-md;

        & svg {
            & path {
                fill: $accent;
            }
        }

        &.mil-prev {
            margin-right: 10px;
            transform: rotate(180deg);
        }

        &.swiper-button-disabled {
            opacity: .1;
            cursor: not-allowed;
        }

        &:hover {
            transform: scale(1.15);

            &.mil-prev {
                transform: rotate(180deg) scale(1.15);
            }
        }
    }

    &.mil-reviews-nav {
        position: absolute;
        top: 160px;
        left: 0;
        width: 100%;
        justify-content: space-between;

        @media screen and (max-width: 992px) {
            top: 0;
        }
    }

    &.mil-soft {
        & .mil-slider-arrow {
            background-color: $gs-10;

            & svg {
                & path {
                    fill: $dark;
                }
            }
        }
    }
}

/* -------------------------------------------

blog

------------------------------------------- */

.mil-blog-card {
    display: block;

    & .mil-cover-frame {
        position: relative;
        overflow: hidden;
        padding-bottom: 65%;
        margin-bottom: 30px;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            transition: $t-md;
        }
    }

    & .mil-post-descr {
        padding: 30px 30px 0 0;

        & .mil-post-text {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &:hover {
        & .mil-cover-frame {
            & img {
                transform: scale(1.05);
            }
        }
    }

    &.mil-blog-card-hori {
        padding-top: 60px;
        border-top: solid 1px $gs-10;
        display: flex;
        align-items: center;

        & .mil-cover-frame {
            margin-bottom: 0;
            width: 450px;
            padding-bottom: 27%;
        }

        & .mil-post-descr {
            width: calc(100% - 450px);
            padding: 0;
            padding-left: 60px;
        }

        @media screen and (max-width: 992px) {
            display: block;
            padding-top: 0;
            border: none;

            & .mil-cover-frame {
                width: 100%;
                padding-bottom: 65%;
                margin-bottom: 30px;
            }

            & .mil-post-descr {
                width: 100%;
                padding: 30px 30px 0 0;
            }
        }
    }
}


.mil-labels {
    display: flex;
    align-items: center;
    padding: 0;

    & .mil-label {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
}


.mil-pagination {
    padding-top: 60px;
    border-top: solid 1px $dt-10;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.mil-pagination-btn {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50%;
    background-color: $dt-10;
    color: $dark;
    text-align: center;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
}

.mil-pagination-btn.mil-active {
    background-color: $accent;
}

.mil-category-list {
    display: flex;

    & li {
        list-style-type: none;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        & a {
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            padding: 0 20px;
            border-radius: 40px;
            color: $dark;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 2px;
            transition: $t-md;

            &:hover {
                background-color: $gs-10;
            }

            &.mil-active {
                background-color: $accent;
            }

        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        & li {
            margin-right: 0;
            margin-bottom: 10px;
            align-items: flex-start;

            &:last-child {
                margin-bottom: 0;
            }

            & a {
                justify-content: flex-start;
            }
        }
    }
}

/* -------------------------------------------

footer

------------------------------------------- */

.mil-footer-menu {
    margin-bottom: 120px;

    & ul {
        & li {
            list-style-type: none;
            margin-bottom: 30px;

            & a {
                display: block;
                font-weight: 500;
                font-size: $h4;
                color: $lt-90;
                transition: $t-md;

                &:hover {
                    transform: translateX(10px);
                    color: $accent;
                }

                @media screen and (max-width: 768px) {
                    font-size: $h4 - 6px;
                }
            }

            &.mil-active {
                & a {
                    color: $accent;

                    &:hover {
                        transform: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 60px;
    }
}

.mil-subscribe-form {
    position: relative;
    height: 70px;
    width: 100%;

    & input {
        height: 100%;
        width: 100%;
        background-color: $lt-10;
        color: $dark;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: none;
        border-radius: 70px;
        padding: 0 0 0 50px;
        transition: $t-md;

        &::placeholder {
            color: $gs-50;
            font-family: $font-1;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        &:focus {
            background-color: $light;
            outline: inherit;
        }

        &:hover {
            background-color: $light;
        }
    }

    & .mil-button {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &.mil-subscribe-form-2 {
        & input {
            height: 100%;
            width: 100%;
            background-color: $dt-10;
        }
    }
}

/* -------------------------------------------

portfolio

------------------------------------------- */
.mil-portfolio-item {
    display: block;

    & .mil-cover-frame {
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;

        & .mil-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: $t-md;
            }
        }

        &.mil-vert {
            padding-bottom: 130%;
        }

        &.mil-hori {
            padding-bottom: 65%;
        }
    }

    &:hover {
        & .mil-cover-frame {
            & .mil-cover {
                & img {
                    transform: scale(1.05);
                }
            }
        }
    }

    &.mil-slider-item {
        & .mil-cover-frame {
            margin-bottom: 30px;
            height: calc(100vh - 360px);

            @media screen and (max-width: 992px) {
                height: auto;
                padding-bottom: 100%;
            }
        }

        & .mil-descr {
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 180px;
            background-color: $gs-5;
            padding: 0 60px;

            @media screen and (max-width: 992px) {
                padding: 30px;
                flex-direction: column;
                align-items: flex-start;
                height: auto;

                & .mil-descr-text {
                    margin-bottom: 30px;
                }
            }
        }

        &:hover {
            & .mil-cover-frame {
                & .mil-cover {
                    & img {
                        transform: none;
                    }
                }
            }
        }
    }
}


.swiper-slide {
    & .mil-portfolio-item {
        & .mil-descr {
            pointer-events: none;
        }
    }

    &.swiper-slide-active {
        & .mil-portfolio-item {
            & .mil-descr {
                pointer-events: all;
            }
        }
    }
}

.mil-portfolio {
    position: relative;

    & .mil-lines-place {
        position: absolute;
        left: 40.7%;

        &.mil-lines-long {
            top: 1289px;
        }
    }
}

.mil-portfolio-slider-frame {
    z-index: 0;
    padding-top: 150px;

    @media screen and (max-width: 992px) {
        padding-top: 180px;
    }
}

.mil-portfolio-nav {
    background-color: $gs-5;
    width: 100%;
    padding: 0 60px;
    height: 180px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & .mil-portfolio-btns-frame {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        height: 70px;
        border-radius: 70px;
        background-color: $light;
    }

    @media screen and (max-width: 992px) {
        position: static;
        height: auto;
        padding: 30px 0 90px;
        justify-content: flex-start;
        background-color: $light;

        & .mil-portfolio-btns-frame {
            width: 100%;
            height: auto;
            padding: 0;
        }
    }
}

.swiper-portfolio-pagination {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 15px;
}

.mil-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    align-items: center;
    border-bottom: solid 1px $dt-10;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: 30px;
    }
}


.mil-works-nav {
    padding-top: 60px;
    border-top: solid 1px $dt-10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .mil-disabled {
        opacity: .2;
        cursor: not-allowed;
    }

    @media screen and (max-width: 768px) {
        padding-top: 0;
        border-top: none;
        justify-content: center;

        & .mil-link {
            display: none;

            &:last-child {
                display: flex;
            }
        }
    }
}

/***************************

map

***************************/

.mil-map-frame {
    background-color: $gs-10;
    pointer-events: all;
    height: 600px;
    position: relative;
    overflow: hidden;

    & .mil-map {
        position: absolute;
        top: -25%;
        left: -25%;
        width: 150%;
        height: 150%;
        transition: $t-md;

        & iframe {
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        & .mil-map {}
    }
}

/* -------------------------------------------

accordion

------------------------------------------- */

.mil-accordion-group {
    border-bottom: solid 1px $dt-20;
    margin-bottom: 30px;

    & .mil-accordion-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 30px;

        & .mil-symbol {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: $dark;
            background-color: $gs-10;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            & .mil-plus,
            & .mil-minus {
                position: absolute;
            }

            & .mil-minus {
                transform: translateY(-2px);
            }
        }

        & .mil-accordion-head {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 500;
            color: $dark;
        }
    }
}

.mil-accordion-content {
    height: 0;
    overflow: hidden;
}


/***************************

price

***************************/

.mil-price-card {
    display: block;
    width: 100%;
    border-top: solid 1px $lt-10;
    border-bottom: solid 1px $lt-10;
    padding: 60px 0 30px;

    & .mil-price-number {
        padding-top: 10px;
        line-height: 70%;
        font-size: $h2 - 14px;
        font-weight: 500;

        & .mil-thin {
            font-weight: 200;
            font-size: 18px;
        }
    }
}


/***************************

404

***************************/

.mil-404-banner {
    & .mil-animation-frame {
        z-index: 2;
    }
}

.mil-404-frame {
    padding-top: 100px;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mil-404-text {
    display: block;
    text-align: center;
    color: $light;
}


.mil-scale-frame {
    transform: scale(2.3);
    text-align: center;
    margin-bottom: 90px;

    @media screen and (max-width: 530px) {
        transform: scale(1.3);
        margin-bottom: 50px;
    }
}

.mil-404 {
    position: relative;
    color: $light;
    font-size: 6em;
    font-weight: 500;
    animation: glitch-skew 1s infinite linear alternate-reverse;

    &::before {
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 $accent;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow: -2px 0 $gs-40, 2px 2px $gs-10;
        animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;

    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
        }
    }
}

/***************************

images

***************************/

.mil-image-frame {
    position: relative;
    overflow: hidden;

    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &.mil-horizontal {
        padding-bottom: 65%;
    }

    &.mil-vertical {
        padding-bottom: 140%;
    }

    &.mil-square {
        padding-bottom: 100%;
    }

    &.mil-fw {
        padding-bottom: 50%;
    }

    & .mil-zoom-btn {
        opacity: 0;
        transform: translateY(15px);
        position: absolute;
        top: 30px;
        right: 30px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $lt-80;
        transition: $t-md;

        &:hover {
            background-color: $light;
        }

        & img {
            height: 17px;
            width: 17px;
        }
    }

    &:hover {
        & .mil-zoom-btn {
            opacity: 1;
            transform: translateY(0);
            z-index: 2;
        }
    }
}

/***************************

page transitions

***************************/
.mil-frame {
    opacity: 1;
    transform: scale(1);
    transition: .6s;
}

.mil-curtain {
    position: fixed;
    pointer-events: none;
    z-index: 4;
    background-color: $dark;
    opacity: 0;
    width: 100%;
    height: 100vh;
    transition: .6s;
}

.mil-main-transition {
    transition: .6s;
    margin-top: 0;
    transition-delay: .3s;

    & .mil-animation-frame {
        opacity: 1;
        margin-top: 0;
        transition: .6s;
        transition-delay: .3s;
    }

    & .mil-lines-place {
        opacity: 1;
        margin-top: 0;
        transition: .6s;
        transition-delay: .3s;
    }
}

html.is-animating {
    & .mil-frame {
        opacity: 0;
        transition-delay: .5s;
        transform: scale(.9);

        @media screen and (max-width: 1200px) {
            transform: scale(1);
            opacity: 1;
        }
    }

    & .mil-curtain {
        opacity: 1;
        pointer-events: all;
    }

    & .mil-main-transition {
        margin-top: .1px;

        & .mil-animation-frame {
            opacity: 0;
            margin-top: -90px;
            transition-delay: 0s;
        }

        & .mil-lines-place {
            opacity: 0;
            margin-top: 90px;
            transition-delay: 0s;
        }
    }
}
@media (max-width: 600px) {
    .mil-frame {
      position: relative;
    }
    .mil-breadcrumbs.mil-light{
        padding: 0;
    }
}
html.is-animating {
    & .mil-menu-frame {
        opacity: 0;
        transition-delay: .3s;
    }
}
.fixed {
    position: fixed;
  }