/* -------------------------------------------

Name: 		Ruizarch
Version:    1.0
Developer:	Nazar Miller (millerDigitalDesign)
Portfolio:  https://themeforest.net/user/millerdigitaldesign/portfolio?ref=MillerDigitalDesign

p.s. I am available for Freelance hire (UI design, web development). email: miller.themes@gmail.com

------------------------------------------- */
/*--------------------------------------------

1. common
    - main
    - typography
    - link
    - button
    - form
    - breadcrumbs
    - backgrounds
    - spaces
2. components
    - preloader
    - cursor
    - hidden elements
    - scrollbar
    - frame
    - menu button
    - menu 
    - banner
    - circle text
    - lines
    - dodecahedron
    - about
    - partners
    - services
    - team
    - social icons
    - revievs
    - blog
    - footer
    - portfolio
    - map
    - accordion
    - price
    - 404
    - images
    - page transitions


--------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css);

@import url("./plugins/bootstrap-grid.css");
@import url("./plugins/fancybox.min.css");
@import url("./plugins/swiper.min.css");

/* -------------------------------------------

main

------------------------------------------- */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
  line-height: 150%;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}
@media screen and (max-width: 768px) {
  html,
body {
    font-size: 15px;
  }
}

*::-moz-selection {
  color: inherit;
  background-color: transparent;
}

*::selection {
  color: inherit;
  background-color: transparent;
}

.mil-wrapper {
  position: relative;
  /* overflow: hidden; */
  max-width: 100%;
}

.container {
  pointer-events: all;
}

.mil-relative {
  position: relative;
}

.mil-o-hidden {
  overflow: hidden;
}

.mil-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mil-vert-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .mil-mobile-hidden {
    display: none;
  }
}

section {
  position: relative;
  overflow: hidden;
}

/* -------------------------------------------

typography

------------------------------------------- */
h1,
.mil-h1,
h2,
.mil-h12,
h3,
.mil-h3,
h4,
.mil-h4,
h5,
.mil-h5,
h6,
.mil-h6 {
  font-family: "Outfit", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 500;
  line-height: 120%;
}
@media screen and (max-width: 768px) {
  h1 br,
.mil-h1 br,
h2 br,
.mil-h12 br,
h3 br,
.mil-h3 br,
h4 br,
.mil-h4 br,
h5 br,
.mil-h5 br,
h6 br,
.mil-h6 br {
    display: none;
  }
}

h1,
.mil-h1 {
  font-size: 86px;
}
@media screen and (max-width: 1400px) {
  h1,
.mil-h1 {
    font-size: 74px;
  }
}
@media screen and (max-width: 1200px) {
  h1,
.mil-h1 {
    font-size: 72px;
  }
}
@media screen and (max-width: 992px) {
  h1,
.mil-h1 {
    font-size: 58px;
  }
}
@media screen and (max-width: 768px) {
  h1,
.mil-h1 {
    font-size: 34px;
  }
}

h2,
.mil-h2 {
  font-size: 68px;
  line-height: 120%;
}
@media screen and (max-width: 1200px) {
  h2,
.mil-h2 {
    font-size: 56px;
  }
}
@media screen and (max-width: 992px) {
  h2,
.mil-h2 {
    font-size: 44px;
  }
}
@media screen and (max-width: 768px) {
  h2,
.mil-h2 {
    font-size: 36px;
  }
}

h3,
.mil-h3 {
  font-size: 42px;
}
@media screen and (max-width: 1200px) {
  h3,
.mil-h3 {
    font-size: 39px;
  }
}
@media screen and (max-width: 992px) {
  h3,
.mil-h3 {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  h3,
.mil-h3 {
    font-size: 30px;
  }
}

h4,
.mil-h4 {
  font-size: 28px;
}
@media screen and (max-width: 1200px) {
  h4,
.mil-h4 {
    font-size: 26px;
  }
}
@media screen and (max-width: 992px) {
  h4,
.mil-h4 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  h4,
.mil-h4 {
    font-size: 22px;
  }
}

h5,
.mil-h5 {
  font-size: 20px;
  line-height: 150%;
}
@media screen and (max-width: 768px) {
  h5,
.mil-h5 {
    font-size: 18px;
  }
}

h6,
.mil-h6 {
  font-size: 18px;
  line-height: 150%;
}
@media screen and (max-width: 1200px) {
  h6,
.mil-h6 {
    font-size: 17px;
  }
}
@media screen and (max-width: 992px) {
  h6,
.mil-h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  h6,
.mil-h6 {
    font-size: 21px;
  }
}

.mil-no-wrap {
  white-space: nowrap;
}

blockquote {
  padding: 60px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-style: italic;
  border-left: solid 4px rgb(255, 152, 0);
}
@media screen and (max-width: 768px) {
  blockquote {
    padding: 30px;
    font-size: 16px;
  }
}

.mil-text-sm {
  font-size: 15px;
}

.mil-text-lg {
  font-size: 18px;
  line-height: 170%;
}

.mil-text-xl {
  font-size: 22px;
  line-height: 180%;
}
@media screen and (max-width: 768px) {
  .mil-text-xl {
    font-size: 20px;
  }
}

.mil-upper {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
}

.mil-bold {
  font-weight: 500;
}

.mil-thin {
  font-weight: 100;
}

a {
  color: inherit;
  text-decoration: none;
}

.mil-complex-title {
  line-height: normal;
}

.mil-accent {
  color: rgb(255, 152, 0) !important;
}

.mil-light {
  color: rgb(255, 255, 255) !important;
}
.mil-light a {
  color: rgb(255, 255, 255) !important;
}

.mil-muted {
  color: rgba(255, 255, 255, 0.9)!important;
}

.mil-dark {
  color: rgb(0, 0, 0);
}

.mil-light-soft {
  color: rgba(255, 255, 255, 0.4);
}

.mil-dark-soft {
  color: rgba(0, 0, 0, 0.5);
}

.mil-marker {
  background-color: rgb(255, 152, 0);
  padding: 0 5px;
}

.mil-center {
  text-align: center;
}

.mil-left {
  text-align: left;
}

.mil-right {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .mil-sm-center {
    text-align: center;
  }
}

.mil-suptitle {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.mil-suptitle:before {
  content: "";
  position: absolute;
  right: calc(100% + 30px);
  top: 10px;
  width: 600px;
  height: 1px;
  background-color: rgb(255, 255, 255);
  opacity: 0.2;
}
.mil-suptitle.mil-suptitle-right {
  text-align: right;
}
.mil-suptitle.mil-suptitle-right:before {
  left: calc(100% + 30px);
}
@media screen and (max-width: 768px) {
  .mil-suptitle.mil-suptitle-right {
    text-align: center;
  }
  .mil-suptitle.mil-suptitle-right:before {
    display: none;
  }
}
.mil-suptitle.mil-suptitle-dark:before {
  background-color: rgb(0, 0, 0);
  opacity: 0.1;
}
@media screen and (max-width: 768px) {
  .mil-suptitle {
    margin-bottom: 60px;
  }
}

.mil-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.mil-img-frame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 140%;
}
.mil-img-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

/* -------------------------------------------

link

------------------------------------------- */
.mil-link {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}
.mil-link svg {
  margin-left: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-link svg path {
  fill: rgb(255, 255, 255);
}
.mil-link.mil-accent svg {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-link.mil-accent svg path {
  fill: rgb(255, 152, 0);
}
.mil-link.mil-dark svg {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-link.mil-dark svg path {
  fill: rgb(0, 0, 0);
}
.mil-link:hover svg {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.mil-link.mil-down-arrow svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.mil-link.mil-down-arrow:hover svg {
  -webkit-transform: scale(1.15) rotate(90deg);
          transform: scale(1.15) rotate(90deg);
}
.mil-link.mil-icon-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.mil-link.mil-icon-left svg {
  margin-left: 0;
  margin-right: 15px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.mil-link.mil-icon-left:hover svg {
  -webkit-transform: scale(1.15) rotate(180deg);
          transform: scale(1.15) rotate(180deg);
}

/* -------------------------------------------

button

------------------------------------------- */
.mil-button {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  letter-spacing: 2px;
  font-size: 12px;
  background-color: rgb(255, 152, 0);
  color: rgb(0, 0, 0);
  border-radius: 70px;
  padding: 0 15px 0 50px;
  height: 70px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-button span {
  white-space: nowrap;
}
.mil-button svg {
  margin-left: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-button svg path {
  fill: rgb(255, 152, 0);
}
.mil-button.mil-icon-button {
  padding: 15px;
}
.mil-button.mil-icon-button svg {
  margin-left: 0;
}
.mil-button.mil-icon-button-sm {
  padding: 0;
  height: 40px;
}
.mil-button.mil-icon-button-sm svg {
  margin-left: 0;
  background-color: rgb(255, 152, 0);
}
.mil-button.mil-icon-button-sm svg path {
  fill: rgb(0, 0, 0);
}
.mil-button:hover {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
.mil-button:hover svg {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.mil-button.mil-arrow-down svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.mil-button.mil-arrow-down:hover {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
.icons .icon {
  margin-left: 1rem;
  font-size: 18px;
}
.icons .icon-black {
  margin-left: 1rem;
  font-size: 18px;
  background-color: #111;
  color: rgb(255, 152, 0);
  border-radius: 50%;
  padding: 12px;
}
.icons-services .icon-black {
  font-size: 16px;
  color: #111;
  background-color: rgb(255, 152, 0);
  border-radius: 50%;
  padding: 12px;
}
/* .whatsapp{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  background: #fff;
  padding: 8px 12px;
  border-radius: 50%;
} */
.whatsapp{
  color: #fff;
  font-size: 40px;
  transform: rotate(90deg);
  background: rgb(255, 152, 0);
  padding: 8px 12px;
  border-radius: 50%;
}
.icons .icon-white {
  margin-left: 1rem;
  font-size: 18px;
  color: #111;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 12px;
}
.icons:hover .icon-black,
.icons:hover .icon-white,
.icons:hover .icon {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
.mil-button.mil-arrow-down:hover svg {
  -webkit-transform: scale(1.15) rotate(90deg);
          transform: scale(1.15) rotate(90deg);
}
@media screen and (max-width: 992px) {
  .mil-button {
    height: 60px;
    padding: 0 10px 0 40px;
  }
  .mil-button svg {
    margin-left: 25px;
  }
}

/* -------------------------------------------

form

------------------------------------------- */
input,
textarea {
  position: relative;
  height: 70px;
  padding: 0 30px;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
  color: rgb(0, 0, 0);
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}
input:focus,
textarea:focus {
  outline: inherit;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
input::-moz-placeholder, textarea::-moz-placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
input::placeholder,
textarea::placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

textarea {
  padding: 15px 30px;
  height: 300px;
}

/* -------------------------------------------

breadcrumbs

------------------------------------------- */
.mil-breadcrumbs {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.mil-breadcrumbs:before {
  content: "";
  position: absolute;
  right: calc(100% + 30px);
  top: 10px;
  width: 100vw;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.mil-breadcrumbs li {
  list-style-type: none;
}
.mil-breadcrumbs li:after {
  content: "/";
  margin: 0 15px;
  color: rgb(0, 0, 0);
}
.mil-breadcrumbs li a {
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-breadcrumbs li a:hover {
  color: rgb(255, 152, 0);
}
.mil-breadcrumbs li:last-child {
  margin-right: 0;
  cursor: not-allowed;
}
.mil-breadcrumbs li:last-child a {
  opacity: 0.4;
  pointer-events: none;
}
.mil-breadcrumbs li:last-child:after {
  display: none;
}
.mil-breadcrumbs.mil-light:before {
  background-color: rgba(255, 255, 255, 0.1);
}
.mil-breadcrumbs.mil-light li:after {
  color: rgba(255, 255, 255, 0.9);
}
.mil-breadcrumbs.mil-light li a:hover {
  color: rgb(255, 152, 0) !important;
}
.mil-breadcrumbs.mil-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* -------------------------------------------

backgrounds

------------------------------------------- */
.mil-dark-bg {
  position: relative;
  background-color: #303D57;
}
.mil-dark-bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  /* -webkit-backdrop-filter: invert(100%); */
  /* backdrop-filter: invert(100%);  */
}
.mil-dark-bg .mi-invert-fix {
  height: 100%;
  position: relative;
  pointer-events: none;
  z-index: 3;
}
.mil-dark-bg .mi-invert-fix .container {
  pointer-events: all;
}

.mil-soft-bg {
  background-color: rgb(242, 242, 242);
}

/* -------------------------------------------

spaces

------------------------------------------- */
.mil-mr-30 {
  margin-right: 30px;
}

.mil-mb-5 {
  margin-bottom: 5px;
}

.mil-mb-10 {
  margin-bottom: 10px;
}

.mil-mb-15 {
  margin-bottom: 15px;
}

.mil-mb-20 {
  margin-bottom: 15px;
}

.mil-mb-30 {
  margin-bottom: 30px;
}

.mil-mb-60 {
  margin-bottom: 60px;
}

.mil-mb-90 {
  margin-bottom: 90px;
}

.mil-mb-120 {
  margin-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .mil-mb-120 {
    margin-bottom: 90px;
  }
}

.mil-mt-suptitle-offset {
  margin-top: 70px;
}
@media screen and (max-width: 992px) {
  .mil-mt-suptitle-offset {
    margin-top: 30px;
  }
}

.mil-p-120-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .mil-p-120-120 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.mil-p-120-90 {
  padding-top: 120px;
  padding-bottom: 90px;
}
@media screen and (max-width: 992px) {
  .mil-p-120-90 {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.mil-p-0-120 {
  padding-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .mil-p-0-120 {
    padding-bottom: 90px;
  }
}

.mil-p-120-0 {
  padding-top: 120px;
}
@media screen and (max-width: 992px) {
  .mil-p-120-0 {
    padding-top: 90px;
  }
}

.mil-p-120-60 {
  padding-top: 120px;
  padding-bottom: 60px;
}
@media screen and (max-width: 992px) {
  .mil-p-120-60 {
    padding-top: 90px;
    padding-bottom: 30px;
  }
}

.mil-p-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.mil-p-60-30 {
  padding-top: 60px;
  padding-bottom: 30px;
}
.mil-p-90-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
@media screen and (max-width: 992px) {
  .mil-p-90-90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.mil-p-90-120 {
  padding-top: 90px;
  padding-bottom: 120px;
}
@media screen and (max-width: 992px) {
  .mil-p-90-120 {
    padding-top: 60px;
    padding-bottom: 90px;
  }
}

.mil-p-0-90 {
  padding-bottom: 90px;
}
@media screen and (max-width: 992px) {
  .mil-p-0-90 {
    padding-bottom: 60px;
  }
}

.mil-p-0-30 {
  padding-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .mil-p-0-30 {
    padding-bottom: 0;
  }
}

.mil-p-120-30 {
  padding-top: 120px;
  padding-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .mil-p-120-30 {
    padding-top: 90px;
    padding-bottom: 0;
  }
}

.mil-adaptive-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media screen and (max-width: 992px) {
  .mil-adaptive-right {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.mil-btn-space {
  margin-right: 30px;
}
@media screen and (max-width: 500px) {
  .mil-btn-space {
    margin-right: 50px;
    margin-bottom: 30px;
  }
}

/* -------------------------------------------

preloader

------------------------------------------- */
.mil-preloader {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #303D57;
}
/* @media (max-width: 600px) {
  .mil-preloader{
    display: none;
  }
} */
.mil-preloader .mil-preloader-animation {
  opacity: 0;
  position: relative;
  height: 100vh;
  color: rgb(255, 255, 255);
}
.mil-preloader .mil-preloader-animation .mil-pos-abs {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-preloader .mil-preloader-animation .mil-pos-abs p {
  opacity: 0;
  margin-right: 15px;
}
@media screen and (max-width: 992px) {
  .mil-preloader .mil-preloader-animation .mil-pos-abs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
            text-align: center;
  }
  .mil-preloader .mil-preloader-animation .mil-pos-abs p {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.mil-preloader .mil-preloader-animation .mil-pos-abs .mil-reveal-frame {
  position: relative;
  padding: 0 30px;
}
.mil-preloader .mil-preloader-animation .mil-pos-abs .mil-reveal-frame .mil-reveal-box {
  z-index: 4;
  position: absolute;
  opacity: 0;
  height: 100%;
  background-color: rgb(255, 152, 0);
}
.mil-preloader.mil-hidden {
  pointer-events: none;
}

/* -------------------------------------------

cursor

------------------------------------------- */
.mil-ball {
  width: 20px;
  height: 20px;
  position: fixed;
  z-index: 10;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0.1;
}
.mil-ball .mil-icon-1 {
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.mil-ball .mil-icon-1 svg {
  fill: rgba(255, 255, 255, 0.9);
}
.mil-ball .mil-more-text, .mil-ball .mil-choose-text {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.mil-ball.mil-accent .mil-icon-1 svg {
  fill: rgb(0, 0, 0);
}
.mil-ball.mil-accent .mil-more-text, .mil-ball.mil-accent .mil-choose-text {
  color: rgb(0, 0, 0);
}
@media screen and (max-width: 1200px) {
  .mil-ball {
    display: none;
  }
}

/* -------------------------------------------

hidden elements

------------------------------------------- */
.mil-hidden-elements .mil-dodecahedron, .mil-hidden-elements .mil-lines, .mil-hidden-elements .mil-arrow {
  display: none;
}

/* -------------------------------------------

scrollbar

------------------------------------------- */
::-webkit-scrollbar {
  display: none;
}

.mil-progress-track {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background-color: rgb(0, 0, 0);
}
.mil-progress-track .mil-progress {
  width: 4px;
  height: 0;
  background-color: rgb(255, 152, 0);
}
@media screen and (max-width: 992px) {
  .mil-progress-track {
    display: none;
  }
}

/* -------------------------------------------

frame

------------------------------------------- */
.mil-logo {
  font-size: 42px;
  font-weight: 500;
  line-height: 100%;
}
.large-icon {
  font-size: 42px;
  font-weight: 500;
  line-height: 100%;
  position: absolute;
  top: 50px;
  left: 20px;
}
@media screen and (max-width: 1200px) {
  .large-icon {
    display: none;
  }
}
@media (max-width: 600px) {
  .large-icon {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .mil-logo {
    font-size: 36px;
  }
}

.mil-frame {
  padding: 50px 60px 60px 60px;
  position: fixed;
  z-index: 2;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .mil-frame {
    padding: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .mil-frame {
    padding: 0;
    z-index: 999;
    height: 90px;
  }
}
@media screen and (max-width: 1200px) {
  .mil-frame .mil-frame-top {
    height: 90px;
    background-color: #303D57;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    padding: 0 30px;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
  .mil-frame .mil-frame-top .mil-logo {
    color: rgb(255, 255, 255);
  }
  .mil-frame .mil-frame-top .mil-menu-btn span, .mil-frame .mil-frame-top .mil-menu-btn span:after, .mil-frame .mil-frame-top .mil-menu-btn span:before {
    background: rgb(255, 255, 255);
  }
}
.mil-frame .mil-frame-bottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media screen and (max-width: 1200px) {
  .mil-frame .mil-frame-bottom {
    display: none;
  }
}
.mil-frame .mil-frame-bottom .mil-current-page {
  pointer-events: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  width: 300px;
  color: rgb(0, 0, 0);
  -webkit-transform: rotate(-90deg) translateX(138px) translateY(-138px);
          transform: rotate(-90deg) translateX(138px) translateY(-138px);
}
.mil-frame .mil-frame-bottom .mil-back-to-top {
  width: 300px;
  -webkit-transform: rotate(-90deg) translateX(130px) translateY(130px);
          transform: rotate(-90deg) translateX(130px) translateY(130px);
  pointer-events: all;
}

.mil-frame-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-frame-top .mil-logo {
  pointer-events: all;
  color: rgb(0, 0, 0);
}

/* -------------------------------------------

menu button

------------------------------------------- */
.mil-menu-btn {
  pointer-events: all;
  height: 28px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
@media screen and (max-width: 992px) {
  .mil-menu-btn {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}
.mil-menu-btn span, .mil-menu-btn span:after, .mil-menu-btn span:before {
  content: "";
  display: block;
  width: 28px;
  height: 2.5px;
  background: rgb(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: inherit;
  transition: inherit;
}
.mil-menu-btn span {
  position: relative;
}
.mil-menu-btn span:after, .mil-menu-btn span:before {
  position: absolute;
}
.mil-menu-btn span:before {
  top: -9px;
}
.mil-menu-btn span:after {
  width: 18px;
  top: 9px;
}
.mil-menu-btn.mil-active span {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.mil-menu-btn.mil-active span:before {
  -webkit-transform: translate(0px, 9px) rotate(-90deg);
          transform: translate(0px, 9px) rotate(-90deg);
}
.mil-menu-btn.mil-active span:after {
  opacity: 0;
  width: 24px;
  -webkit-transform: translate(0px, -9px) rotate(-90deg);
          transform: translate(0px, -9px) rotate(-90deg);
}

/* -------------------------------------------

menu

------------------------------------------- */
.mil-menu-frame {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background-color: #303d57;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-menu-frame .container {
  pointer-events: none;
}
.mil-menu-frame .mil-frame-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 50px 60px;
}
@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-frame-top {
    display: none;
  }
}
.mil-menu-frame .mil-frame-top .mil-logo {
  color: rgb(255, 255, 255);
}
.mil-menu-frame .mil-frame-top .mil-menu-btn span, .mil-menu-frame .mil-frame-top .mil-menu-btn span:after, .mil-menu-frame .mil-frame-top .mil-menu-btn span:before {
  background-color: rgb(255, 255, 255);
}
.mil-menu-frame .mil-main-menu {
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
  opacity: 0;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-main-menu {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
}
.mil-menu-frame .mil-menu-right-frame {
  padding-left: 60px;
  position: relative;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  border-left: solid 1px rgba(255, 255, 255, 0.1);
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-menu-frame .mil-menu-right-frame .mil-menu-right {
  padding-bottom: 60px;
}
.mil-menu-frame .mil-menu-right-frame .mil-animation-in {
  position: absolute;
  top: -320px;
  right: 0;
  opacity: 0;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-menu-right-frame {
    display: none;
  }
}
@media screen and (max-height: 800px) {
  .mil-menu-frame .mil-menu-right-frame {
    display: none;
  }
}
.mil-menu-frame.mil-active {
  opacity: 1;
  pointer-events: all;
}
.mil-menu-frame.mil-active .container {
  pointer-events: all;
}
.mil-menu-frame.mil-active .mil-main-menu {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.mil-menu-frame.mil-active .mil-menu-right-frame {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.mil-menu-frame.mil-active .mil-menu-right-frame .mil-animation-in {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}

.mil-menu-list li {
  list-style-type: none;
  margin-bottom: 15px;
}
.mil-menu-list li:last-child {
  margin-bottom: 0;
}
.mil-menu-list li a {
  display: block;
  -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-menu-list li a:hover {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
  color: rgb(255, 255, 255);
}
.mil-menu-list.mil-hori-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mil-menu-list.mil-hori-list li {
  margin-bottom: 0;
  margin-right: 30px;
}
.mil-menu-list.mil-dark li a {
  color: rgb(0, 0, 0);
}

.mil-main-menu {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-main-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mil-main-menu ul li {
  margin-bottom: 40px;
}
.mil-main-menu ul li:last-child {
  margin-bottom: 0;
}
.mil-main-menu ul li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 34px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
@media screen and (max-width: 992px) {
  .mil-main-menu ul li a {
    font-size: 26px;
  }
}
.mil-main-menu ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-main-menu ul li a:hover {
  color: rgb(255, 255, 255);
}
.mil-main-menu ul li a.mil-active {
  padding-left: 25px;
}
.mil-main-menu ul li a.mil-active:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: rgb(255, 152, 0);
}
.mil-main-menu ul li.mil-active > a {
  color: rgb(255, 152, 0);
}
.mil-main-menu ul li.mil-active > a:hover {
  color: rgb(255, 152, 0) !important;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
.mil-main-menu ul li.mil-has-children > a:hover {
  color: rgb(255, 255, 255);
  padding-left: 25px;
}
.mil-main-menu ul li.mil-has-children > a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.mil-main-menu ul li.mil-has-children .mil-active {
  color: rgb(255, 152, 0);
}
.mil-main-menu ul li.mil-has-children ul {
  padding-left: 25px;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-main-menu ul li.mil-has-children ul li {
  margin-bottom: 5px;
}
.mil-main-menu ul li.mil-has-children ul li:first-child {
  margin-top: 40px;
}
.mil-main-menu ul li.mil-has-children ul li a {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
}
.mil-main-menu ul li.mil-has-children ul li a:before {
  display: none;
}
.mil-main-menu ul li.mil-has-children ul li a:hover {
  color: rgba(255, 255, 255, 0.8);
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}
.mil-main-menu ul li.mil-has-children ul.mil-active {
  max-height: 200px;
}
@media screen and (max-width: 1200px) {
  .mil-main-menu {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mil-main-menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mil-main-menu ul li {
    margin-bottom: 30px;
  }
  .mil-main-menu ul li a {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mil-main-menu ul li a:before {
    display: none;
  }
  .mil-main-menu ul li a.mil-active {
    padding-left: 0 !important;
  }
  .mil-main-menu ul li.mil-has-children ul {
    padding-left: 0;
  }
  .mil-main-menu ul li.mil-has-children ul li:first-child {
    margin-top: 30px;
  }
}

/* -------------------------------------------

banner

------------------------------------------- */
.mil-banner {
  height: 100vh;
}
.mil-banner .container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.mil-banner .mil-banner-content {
  width: 100%;
  padding-bottom: 120px;
  position: relative;
}
@media screen and (max-width: 992px) {
  .mil-banner .mil-banner-content {
    padding-bottom: 90px;
  }
}
.mil-banner .mil-lines-place {
  position: absolute;
  left: 0;
  bottom: calc(100% + 120px);
}

.mil-inner-banner .mil-banner-content {
  padding: 150px 0 0 0;
}
@media screen and (max-width: 1200px) {
  .mil-inner-banner .mil-banner-content {
    padding: 180px 0 0 0;
  }
}
@media screen and (max-width: 500px) {
  .mil-inner-banner .mil-banner-content {
    padding: 90px 0 0 0;
  }
}

.mil-banner-personal {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}
.mil-banner-personal .mil-banner-content {
  position: relative;
  padding-top: 90px;
}
@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content {
    padding-top: 180px;
  }
}
.mil-banner-personal .mil-banner-content .mil-personal-text {
  margin-bottom: 300px;
}
@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-personal-text {
    margin-bottom: 80px;
    text-align: center;
  }
}
.mil-banner-personal .mil-banner-content .mil-banner-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 60px 0 90px;
  background-color: rgb(255, 255, 255);
  height: 180px;
  position: absolute;
  top: calc(100vh - 180px);
  left: 0;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
    padding: 60px 30px;
  }
  .mil-banner-personal .mil-banner-content .mil-banner-panel h5 {
    margin-bottom: 60px;
    text-align: center;
    width: 80%;
  }
}
.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 30px;
  padding: 0 50px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 70px;
  border-radius: 70px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame:hover {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
}
@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame {
    margin-right: 0;
    margin-bottom: 30px;
    height: 60px;
  }
}
.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-button {
  margin: 0 !important;
}
@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    bottom: 0;
    top: auto;
  }
}
@media screen and (max-width: 768px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    position: static;
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 992px) {
  .mil-banner-personal {
    height: auto;
  }
}

.mil-portrait-frame {
  position: relative;
}
.mil-portrait-frame img {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.mil-portrait-frame .mil-nimbus {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 152, 0) 0%, rgba(250, 168, 69, 0) 100%);
  width: 100%;
  padding-bottom: 100%;
  position: absolute;
  opacity: 0.6;
}
@media screen and (max-width: 768px) {
  .mil-portrait-frame {
    height: 400px;
  }
  .mil-portrait-frame img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
}

/* -------------------------------------------

circle text

------------------------------------------- */
.mil-circle-text {
  position: absolute;
  right: 0;
  bottom: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 140px;
  height: 140px;
}
.mil-circle-text .mil-ct-svg {
  -webkit-transform: scale(2);
          transform: scale(2);
  width: 140px;
  height: 140px;
}
.mil-circle-text .mil-ct-svg text {
  fill: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-circle-text .mil-button {
  position: absolute;
}
.mil-circle-text:hover svg text {
  fill: rgb(255, 255, 255);
}
@media screen and (max-width: 992px) {
  .mil-circle-text {
    display: none;
  }
}

/* -------------------------------------------

lines

------------------------------------------- */
@-webkit-keyframes move {
  from {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
  }
  50% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
  to {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
  }
}
@keyframes move {
  from {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
  }
  50% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
  }
  to {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
  }
}
.mil-lines-place .mil-lines {
  opacity: 0.05;
  pointer-events: none;
}
@media screen and (max-width: 992px) {
  .mil-lines-place .mil-lines {
    display: none;
  }
}
.mil-lines-place .mil-lines path, .mil-lines-place .mil-lines rect, .mil-lines-place .mil-lines line {
  stroke: #fff!important; /*rgb(0, 0, 0)*/
}
.mil-lines-place .mil-lines .mil-move {
  -webkit-animation: move 10s linear infinite;
          animation: move 10s linear infinite;
}
.mil-lines-place.mil-light .mil-lines {
  opacity: 0.2;
}
.mil-lines-place.mil-light .mil-lines path, .mil-lines-place.mil-light .mil-lines rect, .mil-lines-place.mil-light .mil-lines line {
  stroke: rgb(255, 255, 255);
}

/* -------------------------------------------

dodecahedron

------------------------------------------- */
.mil-animation-frame {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  -webkit-animation: jump 10s linear infinite;
          animation: jump 10s linear infinite;
  pointer-events: none;
}

.mil-animation {
  position: absolute;
  display: inline-block;
  width: 300px;
  height: 300px;
  opacity: 0.2;
  pointer-events: none;
}

.mil-position-1 {
  top: 100px;
  right: 100px;
}
.mil-position-1 .mil-pentagon div {
  border-top: 0.1px solid rgb(255, 255, 255);
}

.mil-position-2 {
  top: -60px;
  left: 15%;
}
.mil-position-2 .mil-pentagon div {
  border-top: 1px solid rgb(255, 255, 255);
}
.mil-position-2 .mil-dodecahedron {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
@media screen and (max-width: 1400px) {
  .mil-position-2 {
    display: none;
  }
}

.mil-position-3 {
  bottom: -100px;
  right: 35%;
}
.mil-position-3 .mil-pentagon div {
  border-top: 1px solid rgb(255, 255, 255);
}
.mil-position-3 .mil-dodecahedron {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@media screen and (max-width: 1400px) {
  .mil-position-3 {
    display: none;
  }
}

.mil-position-4 {
  top: -60px;
  right: 20%;
}
.mil-position-4 .mil-pentagon div {
  border-top: 0.1px solid rgb(255, 255, 255);
}
.mil-position-4 .mil-dodecahedron {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
@media screen and (max-width: 1400px) {
  .mil-position-4 {
    display: none;
  }
}
.mil-position-4.mil-dark .mil-pentagon div {
  border-top: 0.1px solid rgb(0, 0, 0);
}

.mil-dodecahedron {
  position: relative;
  left: 100px;
  top: 40px;
  width: 100px;
  height: 223px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: rotate 100s infinite linear;
          animation: rotate 100s infinite linear;
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
            transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
  }
  to {
    -webkit-transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
            transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
            transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
  }
  to {
    -webkit-transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
            transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
  }
}

.mil-pentagon {
  position: absolute;
  width: 100px;
}
.mil-pentagon:nth-child(1) {
  -webkit-transform: rotateY(0.2turn) translateZ(69px) rotateX(26.5deg);
          transform: rotateY(0.2turn) translateZ(69px) rotateX(26.5deg);
}
.mil-pentagon:nth-child(6) {
  bottom: 0;
  -webkit-transform: rotateY(0.2turn) translateZ(-69px) rotateX(206.5deg);
          transform: rotateY(0.2turn) translateZ(-69px) rotateX(206.5deg);
}
.mil-pentagon:nth-child(2) {
  -webkit-transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
          transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
}
.mil-pentagon:nth-child(7) {
  bottom: 0;
  -webkit-transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
          transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
}
.mil-pentagon:nth-child(3) {
  -webkit-transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
          transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
}
.mil-pentagon:nth-child(8) {
  bottom: 0;
  -webkit-transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
          transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
}
.mil-pentagon:nth-child(4) {
  -webkit-transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
          transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
}
.mil-pentagon:nth-child(9) {
  bottom: 0;
  -webkit-transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
          transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
}
.mil-pentagon:nth-child(5) {
  -webkit-transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
          transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
}
.mil-pentagon:nth-child(10) {
  bottom: 0;
  -webkit-transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
          transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
}
.mil-pentagon:nth-child(11) {
  -webkit-transform: translateZ(69px) rotateX(-90deg);
          transform: translateZ(69px) rotateX(-90deg);
}
.mil-pentagon:nth-child(12) {
  bottom: 0;
  -webkit-transform: translateZ(-69px) rotateX(90deg);
          transform: translateZ(-69px) rotateX(90deg);
}
.mil-pentagon div {
  position: absolute;
  width: 100px;
  height: 69px;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.mil-pentagon div:nth-child(1) {
  -webkit-transform: rotate(0.2turn);
          transform: rotate(0.2turn);
}
.mil-pentagon div:nth-child(2) {
  -webkit-transform: rotate(0.4turn);
          transform: rotate(0.4turn);
}
.mil-pentagon div:nth-child(3) {
  -webkit-transform: rotate(0.6turn);
          transform: rotate(0.6turn);
}
.mil-pentagon div:nth-child(4) {
  -webkit-transform: rotate(0.8turn);
          transform: rotate(0.8turn);
}
.mil-pentagon div:nth-child(5) {
  -webkit-transform: rotate(1turn);
          transform: rotate(1turn);
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
}
/* -------------------------------------------

about

------------------------------------------- */
.mil-about-quote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-about-quote .mil-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
}
.mil-about-quote .mil-avatar:after {
  content: ' " ';
  color: rgb(0, 0, 0);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(255, 152, 0);
  position: absolute;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 36px;
}
.mil-about-quote .mil-avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.mil-about-quote .mil-quote {
  padding-left: 30px;
  width: calc(100% - 90px);
}
@media screen and (max-width: 992px) {
  .mil-about-quote .mil-avatar {
    width: 70px;
    height: 70px;
  }
  .mil-about-quote .mil-avatar:after {
    padding-top: 7px;
    width: 25px;
    height: 25px;
    font-size: 24px;
  }
  .mil-about-quote .mil-quote {
    font-size: 16px;
    padding-left: 30px;
    width: calc(100% - 70px);
  }
}

.mil-about-photo {
  position: relative;
}
.mil-about-photo .mil-lines-place {
  position: absolute;
  top: -120px;
  left: -27%;
}

/* -------------------------------------------

partners

------------------------------------------- */
.mil-infinite-show .swiper-wrapper {
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
}

.mil-partner-frame {
  display: block;
}
.mil-partner-frame img {
  width: 100%;
}

/* -------------------------------------------

services

------------------------------------------- */
.mil-complex-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-complex-text .mil-button {
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .mil-complex-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mil-complex-text .mil-button {
    margin-left: 0;
    margin-top: 60px;
  }
}

.mil-text-image {
  height: 110px;
  width: 200px;
  display: inline-block;
  overflow: hidden;
  /* border-radius: 70px; */
  margin-right: 30px;
}
.mil-text-image img {
  width: 100%;
  height: 100%;
  /* -o-object-fit: cover;
     object-fit: cover; */
  -o-object-position: top;
     object-position: top;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-text-image:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
@media screen and (max-width: 768px) {
  .mil-text-image {
    display: none;
  }
}

.mil-service-card-sm {
  position: relative;
  display: block;
  padding: 60px 30px;
}
.mil-service-card-sm p {
  opacity: 1;
  /* -webkit-transform: translateY(10px);
          transform: translateY(10px); */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  /* -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1); */
  /* transition: 0.4s cubic-bezier(0, 0, 0.3642, 1); */
}
.mil-service-card-sm .mil-button {
  /* -webkit-transform: scale(0.3);
          transform: scale(0.3);
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); */
  opacity: 1;
}
.mil-service-card-sm:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: rgb(255, 152, 0);
  -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}
/* .mil-service-card-sm:hover p {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.mil-service-card-sm:hover .mil-button {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
} */
.mil-service-card-sm:hover:before {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .mil-service-card-sm p {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .mil-service-card-sm .mil-button {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
  }
}

.mil-services-grid {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
.mil-services-grid .mil-services-grid-item {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}
.mil-services-grid .mil-services-grid-item:first-child {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 992px) {
  .mil-services-grid {
    padding-bottom: 90px;
  }
  .mil-services-grid .mil-services-grid-item {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
  .mil-services-grid .mil-services-grid-item:nth-child(3) {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
  }
}
@media screen and (max-width: 768px) {
  .mil-services-grid {
    padding-bottom: 90px;
  }
  .mil-services-grid .mil-services-grid-item {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
}

.mil-service-card-lg {
  display: block;
}
.mil-service-card-lg .mil-descr {
  padding-right: 30px;
}
.mil-service-card-lg.mil-offset {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media screen and (max-width: 992px) {
  .mil-service-card-lg.mil-offset {
    margin-top: 0;
  }
}
@media screen and (max-width: 992px) {
  .mil-service-card-lg {
    margin-bottom: 60px;
  }
}
.mil-service-card-lg.mil-other-card {
  overflow: hidden;
  position: relative;
  border: solid 1px rgb(229, 229, 229);
  padding: 60px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-service-card-lg.mil-other-card .mil-descr {
  padding-right: 0;
}
.mil-service-card-lg.mil-other-card:hover {
  background-color: rgb(255, 152, 0);
}
.mil-service-card-lg.mil-other-card:hover .mil-link svg {
  background-color: rgb(0, 0, 0);
}
.mil-service-card-lg.mil-other-card:hover .mil-link svg path {
  fill: rgb(255, 152, 0);
}
@media screen and (max-width: 1200px) {
  .mil-service-card-lg.mil-other-card {
    padding: 30px;
  }
}

.mil-service-list li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  list-style-type: none;
  padding: 15px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
}
.mil-service-list li:first-child {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
.mil-service-list.mil-light li {
  color: rgba(255, 255, 255, 0.6);
}
.mil-service-list.mil-dark li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.mil-service-list.mil-dark li:first-child {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

/* -------------------------------------------

team

------------------------------------------- */
.mil-team-card {
  position: relative;
  overflow: hidden;
  padding-bottom: 130%;
}
.mil-team-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: rgb(255, 152, 0);
  -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-team-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-team-card.mil-offset-card {
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
}
@media screen and (max-width: 992px) {
  .mil-team-card.mil-offset-card {
    -webkit-transform: none;
            transform: none;
  }
}
.mil-team-card .mil-description {
  opacity: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  pointer-events: none;
  -webkit-transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-team-card .mil-description .mil-secrc-text {
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.mil-team-card .mil-description .mil-secrc-text a {
  color: rgb(255, 255, 255);
  -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-team-card .mil-description .mil-secrc-text a:hover {
  color: rgb(255, 152, 0);
}
.mil-team-card:hover img {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.mil-team-card:hover:after {
  width: 100%;
}
.mil-team-card:hover .mil-description {
  opacity: 1;
  pointer-events: all;
}
.mil-team-card:hover .mil-description .mil-secrc-text {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.mil-team-list {
  position: relative;
}
.mil-team-list .mil-lines-place {
  position: absolute;
  top: -120px;
  left: -22.5%;
}

/* -------------------------------------------

social icons

------------------------------------------- */
.mil-social-icons {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mil-social-icons.mil-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mil-social-icons li {
  list-style-type: none;
  margin-right: 15px;
}
.mil-social-icons li:last-child {
  margin-right: 0;
}
.mil-social-icons li a {
  color: rgb(255, 255, 255);
  font-size: 18px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-social-icons li a:hover {
  color: rgb(255, 152, 0);
}
.mil-social-icons.mil-dark li a {
  color: rgb(0, 0, 0);
}
.mil-social-icons.mil-dark li a:hover {
  color: rgb(255, 152, 0);
}

/* -------------------------------------------

revievs

------------------------------------------- */
.mil-revi-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 992px) {
  .mil-revi-pagination {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.mil-revi-pagination .swiper-pagination-bullet {
  padding: 0;
  width: 110px;
  height: 110px;
  padding: 10px;
  opacity: 1;
  border: none;
  background-color: transparent;
  margin: 0 !important;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
}
@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet {
    width: 90px;
    height: 90px;
  }
}
.mil-revi-pagination .swiper-pagination-bullet:nth-child(2n) {
  margin-top: 30px !important;
}
@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet:nth-child(2n) {
    margin-top: 0 !important;
  }
}
.mil-revi-pagination .swiper-pagination-bullet:hover {
  -webkit-box-shadow: inset 0 0 0 4px rgb(255, 255, 255);
          box-shadow: inset 0 0 0 4px rgb(255, 255, 255);
}
.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot {
  background-color: red;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: top;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
}
@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot {
    width: 70px;
    height: 70px;
  }
}
.mil-revi-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  -webkit-box-shadow: inset 0 0 0 4px rgb(255, 152, 0);
          box-shadow: inset 0 0 0 4px rgb(255, 152, 0);
}
.slick-slide img{
  width: auto !important;
}
@media (max-width: 600px) {
  .slick-slide img{
    width: 75px !important;
    height: 75px !important;
  }
}
.mil-quote-icon {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
}
.mil-quote-icon path {
  fill: rgb(255, 152, 0);
}

.mil-slider-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mil-slider-nav .mil-slider-arrow {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-slider-nav .mil-slider-arrow svg path {
  fill: rgb(255, 152, 0);
}
.mil-slider-nav .mil-slider-arrow.mil-prev {
  margin-right: 10px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.mil-slider-nav .mil-slider-arrow.swiper-button-disabled {
  opacity: 0.1;
  cursor: not-allowed;
}
.mil-slider-nav .mil-slider-arrow:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.mil-slider-nav .mil-slider-arrow:hover.mil-prev {
  -webkit-transform: rotate(180deg) scale(1.15);
          transform: rotate(180deg) scale(1.15);
}
.mil-slider-nav.mil-reviews-nav {
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .mil-slider-nav.mil-reviews-nav {
    top: 0;
  }
}
.mil-slider-nav.mil-soft .mil-slider-arrow {
  background-color: rgb(229, 229, 229);
}
.mil-slider-nav.mil-soft .mil-slider-arrow svg path {
  fill: rgb(0, 0, 0);
}

/* -------------------------------------------

blog

------------------------------------------- */
.mil-blog-card {
  display: block;
}
.mil-blog-card .mil-cover-frame {
  position: relative;
  overflow: hidden;
  padding-bottom: 65%;
  margin-bottom: 30px;
}
.mil-blog-card .mil-cover-frame img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-blog-card .mil-post-descr {
  padding: 30px 30px 0 0;
}
.mil-blog-card .mil-post-descr .mil-post-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mil-blog-card:hover .mil-cover-frame img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.mil-blog-card.mil-blog-card-hori {
  padding-top: 60px;
  border-top: solid 1px rgb(229, 229, 229);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-blog-card.mil-blog-card-hori .mil-cover-frame {
  margin-bottom: 0;
  width: 450px;
  padding-bottom: 27%;
}
.mil-blog-card.mil-blog-card-hori .mil-post-descr {
  width: calc(100% - 450px);
  padding: 0;
  padding-left: 60px;
}
@media screen and (max-width: 992px) {
  .mil-blog-card.mil-blog-card-hori {
    display: block;
    padding-top: 0;
    border: none;
  }
  .mil-blog-card.mil-blog-card-hori .mil-cover-frame {
    width: 100%;
    padding-bottom: 65%;
    margin-bottom: 30px;
  }
  .mil-blog-card.mil-blog-card-hori .mil-post-descr {
    width: 100%;
    padding: 30px 30px 0 0;
  }
}

.mil-labels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}
.mil-labels .mil-label {
  margin-right: 15px;
}
.mil-labels .mil-label:last-child {
  margin-right: 0;
}

.mil-pagination {
  padding-top: 60px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}

.mil-pagination-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  margin-right: 10px;
}

.mil-pagination-btn.mil-active {
  background-color: rgb(255, 152, 0);
}

.mil-category-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mil-category-list li {
  list-style-type: none;
  margin-right: 10px;
}
.mil-category-list li:last-child {
  margin-right: 0;
}
.mil-category-list li a {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  padding: 0 20px;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-category-list li a:hover {
  background-color: rgb(229, 229, 229);
}
.mil-category-list li a.mil-active {
  background-color: rgb(255, 152, 0);
}
@media screen and (max-width: 768px) {
  .mil-category-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mil-category-list li {
    margin-right: 0;
    margin-bottom: 10px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .mil-category-list li:last-child {
    margin-bottom: 0;
  }
  .mil-category-list li a {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

/* -------------------------------------------

footer

------------------------------------------- */
.mil-footer-menu {
  margin-bottom: 120px;
}
.mil-footer-menu ul li {
  list-style-type: none;
  margin-bottom: 30px;
}
.mil-footer-menu ul li a {
  display: block;
  font-weight: 500;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.9);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-footer-menu ul li a:hover {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  color: rgb(255, 152, 0);
}
@media screen and (max-width: 768px) {
  .mil-footer-menu ul li a {
    font-size: 22px;
  }
}
.mil-footer-menu ul li.mil-active a {
  color: rgb(255, 152, 0);
}
.mil-footer-menu ul li.mil-active a:hover {
  -webkit-transform: none;
          transform: none;
}
@media screen and (max-width: 768px) {
  .mil-footer-menu {
    margin-bottom: 60px;
  }
}

.mil-subscribe-form {
  position: relative;
  height: 70px;
  width: 100%;
}
.mil-subscribe-form input {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 70px;
  padding: 0 0 0 50px;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-subscribe-form input::-webkit-input-placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.mil-subscribe-form input::-moz-placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.mil-subscribe-form input:-ms-input-placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.mil-subscribe-form input::-ms-input-placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.mil-subscribe-form input::placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.mil-subscribe-form input:focus {
  background-color: rgb(255, 255, 255);
  outline: inherit;
}
.mil-subscribe-form input:hover {
  background-color: rgb(255, 255, 255);
}
.mil-subscribe-form .mil-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.mil-subscribe-form.mil-subscribe-form-2 input {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

/* -------------------------------------------

portfolio

------------------------------------------- */
.mil-portfolio-item {
  display: block;
}
.mil-portfolio-item .mil-cover-frame {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.mil-portfolio-item .mil-cover-frame .mil-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mil-portfolio-item .mil-cover-frame .mil-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-portfolio-item .mil-cover-frame.mil-vert {
  padding-bottom: 130%;
}
.mil-portfolio-item .mil-cover-frame.mil-hori {
  padding-bottom: 65%;
}
.mil-portfolio-item:hover .mil-cover-frame .mil-cover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.mil-portfolio-item.mil-slider-item .mil-cover-frame {
  margin-bottom: 30px;
  height: calc(100vh - 360px);
}
@media screen and (max-width: 992px) {
  .mil-portfolio-item.mil-slider-item .mil-cover-frame {
    height: auto;
    padding-bottom: 100%;
  }
}
.mil-portfolio-item.mil-slider-item .mil-descr {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 180px;
  background-color: rgb(242, 242, 242);
  padding: 0 60px;
}
@media screen and (max-width: 992px) {
  .mil-portfolio-item.mil-slider-item .mil-descr {
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: auto;
  }
  .mil-portfolio-item.mil-slider-item .mil-descr .mil-descr-text {
    margin-bottom: 30px;
  }
}
.mil-portfolio-item.mil-slider-item:hover .mil-cover-frame .mil-cover img {
  -webkit-transform: none;
          transform: none;
}

.swiper-slide .mil-portfolio-item .mil-descr {
  pointer-events: none;
}
.swiper-slide.swiper-slide-active .mil-portfolio-item .mil-descr {
  pointer-events: all;
}

.mil-portfolio {
  position: relative;
}
.mil-portfolio .mil-lines-place {
  position: absolute;
  left: 40.7%;
}
.mil-portfolio .mil-lines-place.mil-lines-long {
  top: 1289px;
}

.mil-portfolio-slider-frame {
  z-index: 0;
  padding-top: 150px;
}
@media screen and (max-width: 992px) {
  .mil-portfolio-slider-frame {
    padding-top: 180px;
  }
}

.mil-portfolio-nav {
  background-color: rgb(242, 242, 242);
  width: 100%;
  padding: 0 60px;
  height: 180px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mil-portfolio-nav .mil-portfolio-btns-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 15px;
  height: 70px;
  border-radius: 70px;
  background-color: rgb(255, 255, 255);
}
@media screen and (max-width: 992px) {
  .mil-portfolio-nav {
    position: static;
    height: auto;
    padding: 30px 0 90px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    background-color: rgb(255, 255, 255);
  }
  .mil-portfolio-nav .mil-portfolio-btns-frame {
    width: 100%;
    height: auto;
    padding: 0;
  }
}

.swiper-portfolio-pagination {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0 15px;
}

.mil-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 120px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .mil-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
    padding: 30px;
  }
}

.mil-works-nav {
  padding-top: 60px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mil-works-nav .mil-disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
@media screen and (max-width: 768px) {
  .mil-works-nav {
    padding-top: 0;
    border-top: none;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mil-works-nav .mil-link {
    display: none;
  }
  .mil-works-nav .mil-link:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

/***************************

map

***************************/
.mil-map-frame {
  background-color: rgb(229, 229, 229);
  pointer-events: all;
  height: 600px;
  position: relative;
  overflow: hidden;
}
.mil-map-frame .mil-map {
  position: absolute;
  top: -25%;
  left: -25%;
  width: 150%;
  height: 150%;
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-map-frame .mil-map iframe {
  width: 100%;
  height: 100%;
}
/* -------------------------------------------

accordion

------------------------------------------- */
.mil-accordion-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}
.mil-accordion-group .mil-accordion-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  margin-bottom: 30px;
}
.mil-accordion-group .mil-accordion-menu .mil-symbol {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: rgb(0, 0, 0);
  background-color: rgb(229, 229, 229);
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.mil-accordion-group .mil-accordion-menu .mil-symbol .mil-plus, .mil-accordion-group .mil-accordion-menu .mil-symbol .mil-minus {
  position: absolute;
}
.mil-accordion-group .mil-accordion-menu .mil-symbol .mil-minus {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.mil-accordion-group .mil-accordion-menu .mil-accordion-head {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.mil-accordion-content {
  height: 0;
  overflow: hidden;
}

/***************************

price

***************************/
.mil-price-card {
  display: block;
  width: 100%;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding: 60px 0 30px;
}
.mil-price-card .mil-price-number {
  padding-top: 10px;
  line-height: 70%;
  font-size: 54px;
  font-weight: 500;
}
.mil-price-card .mil-price-number .mil-thin {
  font-weight: 200;
  font-size: 18px;
}

/***************************

404

***************************/
.mil-404-banner .mil-animation-frame {
  z-index: 2;
}

.mil-404-frame {
  padding-top: 100px;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mil-404-text {
  display: block;
  text-align: center;
  color: rgb(255, 255, 255);
}

.mil-scale-frame {
  -webkit-transform: scale(2.3);
          transform: scale(2.3);
  text-align: center;
  margin-bottom: 90px;
}
@media screen and (max-width: 530px) {
  .mil-scale-frame {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    margin-bottom: 50px;
  }
}

.mil-404 {
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 6em;
  font-weight: 500;
  animation: glitch-skew 1s infinite linear alternate-reverse;
}
.mil-404::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 2px;
  text-shadow: -2px 0 rgb(255, 152, 0);
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}
.mil-404::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -2px;
  text-shadow: -2px 0 rgb(153, 153, 153), 2px 2px rgb(229, 229, 229);
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(49px, 9999px, 31px, 0);
    -webkit-transform: skew(0.04deg);
            transform: skew(0.04deg);
  }
  5% {
    clip: rect(63px, 9999px, 92px, 0);
    -webkit-transform: skew(0.18deg);
            transform: skew(0.18deg);
  }
  10% {
    clip: rect(86px, 9999px, 74px, 0);
    -webkit-transform: skew(0.4deg);
            transform: skew(0.4deg);
  }
  15% {
    clip: rect(85px, 9999px, 19px, 0);
    -webkit-transform: skew(0.57deg);
            transform: skew(0.57deg);
  }
  20% {
    clip: rect(64px, 9999px, 75px, 0);
    -webkit-transform: skew(0.96deg);
            transform: skew(0.96deg);
  }
  25% {
    clip: rect(77px, 9999px, 17px, 0);
    -webkit-transform: skew(0.61deg);
            transform: skew(0.61deg);
  }
  30% {
    clip: rect(16px, 9999px, 76px, 0);
    -webkit-transform: skew(0.81deg);
            transform: skew(0.81deg);
  }
  35% {
    clip: rect(5px, 9999px, 61px, 0);
    -webkit-transform: skew(0.56deg);
            transform: skew(0.56deg);
  }
  40% {
    clip: rect(79px, 9999px, 81px, 0);
    -webkit-transform: skew(0.05deg);
            transform: skew(0.05deg);
  }
  45% {
    clip: rect(20px, 9999px, 97px, 0);
    -webkit-transform: skew(0.56deg);
            transform: skew(0.56deg);
  }
  50% {
    clip: rect(33px, 9999px, 100px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg);
  }
  55% {
    clip: rect(19px, 9999px, 76px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg);
  }
  60% {
    clip: rect(29px, 9999px, 59px, 0);
    -webkit-transform: skew(0.58deg);
            transform: skew(0.58deg);
  }
  65% {
    clip: rect(19px, 9999px, 95px, 0);
    -webkit-transform: skew(0.83deg);
            transform: skew(0.83deg);
  }
  70% {
    clip: rect(88px, 9999px, 30px, 0);
    -webkit-transform: skew(0.39deg);
            transform: skew(0.39deg);
  }
  75% {
    clip: rect(43px, 9999px, 45px, 0);
    -webkit-transform: skew(0.6deg);
            transform: skew(0.6deg);
  }
  80% {
    clip: rect(30px, 9999px, 4px, 0);
    -webkit-transform: skew(0.89deg);
            transform: skew(0.89deg);
  }
  85% {
    clip: rect(4px, 9999px, 96px, 0);
    -webkit-transform: skew(1deg);
            transform: skew(1deg);
  }
  90% {
    clip: rect(4px, 9999px, 91px, 0);
    -webkit-transform: skew(0.2deg);
            transform: skew(0.2deg);
  }
  95% {
    clip: rect(48px, 9999px, 80px, 0);
    -webkit-transform: skew(0.41deg);
            transform: skew(0.41deg);
  }
  100% {
    clip: rect(10px, 9999px, 58px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(49px, 9999px, 31px, 0);
    -webkit-transform: skew(0.04deg);
            transform: skew(0.04deg);
  }
  5% {
    clip: rect(63px, 9999px, 92px, 0);
    -webkit-transform: skew(0.18deg);
            transform: skew(0.18deg);
  }
  10% {
    clip: rect(86px, 9999px, 74px, 0);
    -webkit-transform: skew(0.4deg);
            transform: skew(0.4deg);
  }
  15% {
    clip: rect(85px, 9999px, 19px, 0);
    -webkit-transform: skew(0.57deg);
            transform: skew(0.57deg);
  }
  20% {
    clip: rect(64px, 9999px, 75px, 0);
    -webkit-transform: skew(0.96deg);
            transform: skew(0.96deg);
  }
  25% {
    clip: rect(77px, 9999px, 17px, 0);
    -webkit-transform: skew(0.61deg);
            transform: skew(0.61deg);
  }
  30% {
    clip: rect(16px, 9999px, 76px, 0);
    -webkit-transform: skew(0.81deg);
            transform: skew(0.81deg);
  }
  35% {
    clip: rect(5px, 9999px, 61px, 0);
    -webkit-transform: skew(0.56deg);
            transform: skew(0.56deg);
  }
  40% {
    clip: rect(79px, 9999px, 81px, 0);
    -webkit-transform: skew(0.05deg);
            transform: skew(0.05deg);
  }
  45% {
    clip: rect(20px, 9999px, 97px, 0);
    -webkit-transform: skew(0.56deg);
            transform: skew(0.56deg);
  }
  50% {
    clip: rect(33px, 9999px, 100px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg);
  }
  55% {
    clip: rect(19px, 9999px, 76px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg);
  }
  60% {
    clip: rect(29px, 9999px, 59px, 0);
    -webkit-transform: skew(0.58deg);
            transform: skew(0.58deg);
  }
  65% {
    clip: rect(19px, 9999px, 95px, 0);
    -webkit-transform: skew(0.83deg);
            transform: skew(0.83deg);
  }
  70% {
    clip: rect(88px, 9999px, 30px, 0);
    -webkit-transform: skew(0.39deg);
            transform: skew(0.39deg);
  }
  75% {
    clip: rect(43px, 9999px, 45px, 0);
    -webkit-transform: skew(0.6deg);
            transform: skew(0.6deg);
  }
  80% {
    clip: rect(30px, 9999px, 4px, 0);
    -webkit-transform: skew(0.89deg);
            transform: skew(0.89deg);
  }
  85% {
    clip: rect(4px, 9999px, 96px, 0);
    -webkit-transform: skew(1deg);
            transform: skew(1deg);
  }
  90% {
    clip: rect(4px, 9999px, 91px, 0);
    -webkit-transform: skew(0.2deg);
            transform: skew(0.2deg);
  }
  95% {
    clip: rect(48px, 9999px, 80px, 0);
    -webkit-transform: skew(0.41deg);
            transform: skew(0.41deg);
  }
  100% {
    clip: rect(10px, 9999px, 58px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg);
  }
}
@-webkit-keyframes glitch-anim2 {
  0% {
    clip: rect(97px, 9999px, 84px, 0);
    -webkit-transform: skew(0.55deg);
            transform: skew(0.55deg);
  }
  5% {
    clip: rect(82px, 9999px, 21px, 0);
    -webkit-transform: skew(0.04deg);
            transform: skew(0.04deg);
  }
  10% {
    clip: rect(51px, 9999px, 99px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg);
  }
  15% {
    clip: rect(42px, 9999px, 97px, 0);
    -webkit-transform: skew(0.03deg);
            transform: skew(0.03deg);
  }
  20% {
    clip: rect(27px, 9999px, 25px, 0);
    -webkit-transform: skew(0.86deg);
            transform: skew(0.86deg);
  }
  25% {
    clip: rect(69px, 9999px, 5px, 0);
    -webkit-transform: skew(0.95deg);
            transform: skew(0.95deg);
  }
  30% {
    clip: rect(38px, 9999px, 67px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg);
  }
  35% {
    clip: rect(8px, 9999px, 90px, 0);
    -webkit-transform: skew(0.67deg);
            transform: skew(0.67deg);
  }
  40% {
    clip: rect(34px, 9999px, 31px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg);
  }
  45% {
    clip: rect(1px, 9999px, 25px, 0);
    -webkit-transform: skew(0.71deg);
            transform: skew(0.71deg);
  }
  50% {
    clip: rect(45px, 9999px, 40px, 0);
    -webkit-transform: skew(0.38deg);
            transform: skew(0.38deg);
  }
  55% {
    clip: rect(84px, 9999px, 96px, 0);
    -webkit-transform: skew(0.3deg);
            transform: skew(0.3deg);
  }
  60% {
    clip: rect(68px, 9999px, 59px, 0);
    -webkit-transform: skew(0.35deg);
            transform: skew(0.35deg);
  }
  65% {
    clip: rect(93px, 9999px, 48px, 0);
    -webkit-transform: skew(0.25deg);
            transform: skew(0.25deg);
  }
  70% {
    clip: rect(19px, 9999px, 40px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg);
  }
  75% {
    clip: rect(76px, 9999px, 56px, 0);
    -webkit-transform: skew(0.79deg);
            transform: skew(0.79deg);
  }
  80% {
    clip: rect(22px, 9999px, 82px, 0);
    -webkit-transform: skew(0.48deg);
            transform: skew(0.48deg);
  }
  85% {
    clip: rect(30px, 9999px, 63px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg);
  }
  90% {
    clip: rect(68px, 9999px, 44px, 0);
    -webkit-transform: skew(0.4deg);
            transform: skew(0.4deg);
  }
  95% {
    clip: rect(12px, 9999px, 36px, 0);
    -webkit-transform: skew(0.61deg);
            transform: skew(0.61deg);
  }
  100% {
    clip: rect(24px, 9999px, 5px, 0);
    -webkit-transform: skew(0.96deg);
            transform: skew(0.96deg);
  }
}
@keyframes glitch-anim2 {
  0% {
    clip: rect(97px, 9999px, 84px, 0);
    -webkit-transform: skew(0.55deg);
            transform: skew(0.55deg);
  }
  5% {
    clip: rect(82px, 9999px, 21px, 0);
    -webkit-transform: skew(0.04deg);
            transform: skew(0.04deg);
  }
  10% {
    clip: rect(51px, 9999px, 99px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg);
  }
  15% {
    clip: rect(42px, 9999px, 97px, 0);
    -webkit-transform: skew(0.03deg);
            transform: skew(0.03deg);
  }
  20% {
    clip: rect(27px, 9999px, 25px, 0);
    -webkit-transform: skew(0.86deg);
            transform: skew(0.86deg);
  }
  25% {
    clip: rect(69px, 9999px, 5px, 0);
    -webkit-transform: skew(0.95deg);
            transform: skew(0.95deg);
  }
  30% {
    clip: rect(38px, 9999px, 67px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg);
  }
  35% {
    clip: rect(8px, 9999px, 90px, 0);
    -webkit-transform: skew(0.67deg);
            transform: skew(0.67deg);
  }
  40% {
    clip: rect(34px, 9999px, 31px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg);
  }
  45% {
    clip: rect(1px, 9999px, 25px, 0);
    -webkit-transform: skew(0.71deg);
            transform: skew(0.71deg);
  }
  50% {
    clip: rect(45px, 9999px, 40px, 0);
    -webkit-transform: skew(0.38deg);
            transform: skew(0.38deg);
  }
  55% {
    clip: rect(84px, 9999px, 96px, 0);
    -webkit-transform: skew(0.3deg);
            transform: skew(0.3deg);
  }
  60% {
    clip: rect(68px, 9999px, 59px, 0);
    -webkit-transform: skew(0.35deg);
            transform: skew(0.35deg);
  }
  65% {
    clip: rect(93px, 9999px, 48px, 0);
    -webkit-transform: skew(0.25deg);
            transform: skew(0.25deg);
  }
  70% {
    clip: rect(19px, 9999px, 40px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg);
  }
  75% {
    clip: rect(76px, 9999px, 56px, 0);
    -webkit-transform: skew(0.79deg);
            transform: skew(0.79deg);
  }
  80% {
    clip: rect(22px, 9999px, 82px, 0);
    -webkit-transform: skew(0.48deg);
            transform: skew(0.48deg);
  }
  85% {
    clip: rect(30px, 9999px, 63px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg);
  }
  90% {
    clip: rect(68px, 9999px, 44px, 0);
    -webkit-transform: skew(0.4deg);
            transform: skew(0.4deg);
  }
  95% {
    clip: rect(12px, 9999px, 36px, 0);
    -webkit-transform: skew(0.61deg);
            transform: skew(0.61deg);
  }
  100% {
    clip: rect(24px, 9999px, 5px, 0);
    -webkit-transform: skew(0.96deg);
            transform: skew(0.96deg);
  }
}
@-webkit-keyframes glitch-skew {
  0% {
    -webkit-transform: skew(3deg);
            transform: skew(3deg);
  }
  10% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg);
  }
  20% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  30% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  40% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg);
  }
  50% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  60% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg);
  }
  70% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  80% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  90% {
    -webkit-transform: skew(4deg);
            transform: skew(4deg);
  }
  100% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg);
  }
}
@keyframes glitch-skew {
  0% {
    -webkit-transform: skew(3deg);
            transform: skew(3deg);
  }
  10% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg);
  }
  20% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  30% {
    -webkit-transform: skew(0deg);
            transform: skew(0deg);
  }
  40% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg);
  }
  50% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  60% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg);
  }
  70% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  80% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg);
  }
  90% {
    -webkit-transform: skew(4deg);
            transform: skew(4deg);
  }
  100% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg);
  }
}
/***************************

images

***************************/
.mil-image-frame {
  position: relative;
  overflow: hidden;
}
.mil-image-frame > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.mil-image-frame.mil-horizontal {
  padding-bottom: 65%;
}
.mil-image-frame.mil-vertical {
  padding-bottom: 140%;
}
.mil-image-frame.mil-square {
  padding-bottom: 100%;
}
.mil-image-frame.mil-fw {
  padding-bottom: 50%;
}
.mil-image-frame .mil-zoom-btn {
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  position: absolute;
  top: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.mil-image-frame .mil-zoom-btn:hover {
  background-color: rgb(255, 255, 255);
}
.mil-image-frame .mil-zoom-btn img {
  height: 17px;
  width: 17px;
}
.mil-image-frame:hover .mil-zoom-btn {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: 2;
}

/***************************

page transitions

***************************/
.mil-frame {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
@media (max-width: 600px) {
  .mil-frame {
    position: relative;
  }
}
.mil-curtain {
  position: fixed;
  pointer-events: none;
  z-index: 4;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.mil-main-transition {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-top: 0;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.mil-main-transition .mil-animation-frame {
  opacity: 1;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.mil-main-transition .mil-lines-place {
  opacity: 1;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

html.is-animating .mil-frame {
  opacity: 0;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
@media screen and (max-width: 1200px) {
  html.is-animating .mil-frame {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
html.is-animating .mil-curtain {
  opacity: 1;
  pointer-events: all;
}
html.is-animating .mil-main-transition {
  margin-top: 0.1px;
}
html.is-animating .mil-main-transition .mil-animation-frame {
  opacity: 0;
  margin-top: -90px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
html.is-animating .mil-main-transition .mil-lines-place {
  opacity: 0;
  margin-top: 90px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

html.is-animating .mil-menu-frame {
  opacity: 0;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.branding .slick-slider .slick-list img {
  width: 100% !important;
  height: 100% !important;
}
.fixed {
  position: fixed;
}