/* .home .slick-slide{
    width: 130px !important;
} */
.home .slick-slide img {
    width: 120px !important;
}
.text-hover:hover{
    color: #fff;
    transition: all .5s ease-in-out;
}
.mobile-view{
    position: fixed;
    bottom: 33px;
    right: 12px;
    z-index: 999;
    transform: rotate(0);
    display: none;
}
@media (max-width: 600px){
    .mobile-view{
        display: block;
    }
    .home .slick-slide{
        width: 100px !important;
    }
    .branding .slick-slider .slick-list {
        height: 50vh !important;
    }
    .home .slick-slide img {
        width: 75px !important;
    }
    .branding .slick-track{
        height: 100% !important;
    }
    .branding .slick-slide div:first-child{
        height: 100% !important;
    }
    .branding .sl-title {
        font-size: 40px;
    }
    .branding .scroll-down-button .fa-angle-down {
        bottom: -20px;
        font-size: 33px;
    }
    
    .branding .slick-next:before, .branding .slick-prev:before {
        font-size: 28px;
    }
    .branding .title {
        font-size: 24px;
        line-height: 31px;
    }
    .branding .portfolio .portfolio-filter .all-iso-item {
        font-size: 40px;
        line-height: 30px;
    }
}
.cls-profile{
    color: #fff;
    border: 1px solid #fff;
    border-radius: 16px;
    display: flex;
    width: fit-content;
    gap: 12px;
    align-items: center;
    font-size: 18px;
    padding: 8px 16px;
    cursor: pointer;
}
.cls-profile:hover{
    color: rgb(255, 152, 0);
    border-color: rgb(255, 152, 0);
}