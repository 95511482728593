// Font Family
$cir-1: 'circular_reguler';
$cir-2: 'circular_reguler_italic';
$cir-3: 'circular_medium';
$cir-4: 'circular_medium_italic';
$cir-5: 'circular_bold';
$cir-6: 'circular_bold_italic';
$cir-7: 'circular_black';
$cir-8: 'circular_black_italic';

// Colors ---------------
$white: #fff;
$black: #000;
$grey:#f7f7fd;
$theme-color: #6366EA;
$text-color: #464646;
$hr-border-color:#eceff8;

// Heading Color
$heading-color: #404040;

// Soft color
$black-soft:#7e7e7e;


// Responsive Variables
$desktop: 'only screen and (min-width: 1600px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';


