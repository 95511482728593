.website{
    margin: 0 0 6rem;
    .card{
        background-color: #dedede;
        color: #000;
        padding: 8px;
        border-radius: 5px;
        .card-title{
            font-size: 16px;
            font-weight: 600;
            padding: 8px 0;
        }
    }
    h2{
        color: #b4b4b4;
        margin-bottom: 20px;
    }
    p{
        color: #b4b4b4;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 28px;
        width: 75%;
        font-weight: 500;
    }
    .design-image{
        position: relative;
        cursor: pointer;
        p{
            position: absolute;
            bottom: 30px;
            left: 30px;
            font-size: 30px;
            opacity: 0;
            transition: opacity .5s ease-in-out;
        }
    }
    .design-image img{
        border-radius: 16px;
    }
    .design-image:hover{
        p{
            opacity: 1;
        }
    }
    .ready{
        margin-top: 8rem;
        background: rgb(255, 152, 0);
        padding: 50px 100px;
        text-align: center;
        border-radius: 20px;
        
        h2{
            font-size: 50px;
            font-weight: 400;
            line-height: 70px;
        }
        h2,
        p{
            color: #fff;
            width: 100%;
        }
        button{
            background: #000;
            color: #fff;
            border-radius: 8px;
            border: none;
            padding: 12px 30px;
            font-size: 20px;
            cursor: pointer;
        }
    }
}
.modal.show .modal-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
}
@media only screen and (max-width: 500px) {
    .ready{
        padding: 50px 30px!important;
    }
    h2{
        font-size: 40px!important;
        line-height: 45px!important;
    }
    p {
        font-size: 20px!important;
        font-weight: 500!important;
        line-height: 28px!important;
        width: 100%!important;
    }
    .design-modal{
        .modal-dialog{
            width: 80%;
        }
        .carousel-control-prev{
            left: -48px;
        }
        .carousel-control-next {
            right: -48px;
        }
    }
}
.carousel-control-prev{
    left: -100px;
}
.carousel-control-next {
    right: -100px;
}
.carousel-indicators{
    display: none;
}