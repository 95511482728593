.website{
    margin: 1rem 0 6rem;
    .card{
        background-color: #dedede51;
        color: #000;
        padding: 8px;
        border-radius: 5px;
        .card-title{
            font-size: 16px;
            font-weight: 600;
            padding: 8px 0;
        }
    }
}