.contact-us{
    align-items: center;
    margin-bottom: 60px;
    .form{
        label{
            color: #fff;
        }
        input{
            height: 40px;
            border-radius: 5px;
            margin-top: 5px;
            background: #dedede;
            text-transform: none;
            font-size: 18px;
        }
        textarea{
            border-radius: 5px;
            margin-top: 5px;
            background: #dedede;
            text-transform: none;
            font-size: 18px;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }
}